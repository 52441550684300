import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Link } from ".."
import styles from "./style.module.scss"
import GreaterThanIcon from "../../images/icons/greater-than.svg"
import LessThanIcon from "../../images/icons/less-than.svg"
import { IndustryType } from "../../propTypes"
import { FLAT_SITE_ROUTES, populateRoute, extractRoute } from "../../routing"

const Breabcrumb = ({ uri, industry }) => {
  function getBreadcrumbData(uriPointer, returnValue = []) {
    if (!uriPointer) return returnValue

    const routePointer = FLAT_SITE_ROUTES[uriPointer]

    if (!routePointer) return returnValue
    returnValue.push(routePointer)
    return getBreadcrumbData(routePointer.parent?.fullPath, returnValue)
  }
  const breadcrumbDataMobileAndTablet = getBreadcrumbData(extractRoute(uri, industry)).reverse()
  const breadcrumbData = breadcrumbDataMobileAndTablet.filter(a => !a.ignoreInBreadcrumb)

  const parent =
    breadcrumbDataMobileAndTablet.length >= 2
      ? breadcrumbDataMobileAndTablet[breadcrumbDataMobileAndTablet.length - 2]
      : null

  return (
    <>
      <span className={classNames(styles.breadPath, "mobile-display-none", "tablet-display-none")}>
        {breadcrumbData.map((link, i, arr) => (
          <span key={link.fullPath}>
            {arr.length - 1 !== i ? (
              <Link.Default url={populateRoute(link.fullPath, industry)}>{link.title}</Link.Default>
            ) : (
              <>{link.title}</>
            )}
            <img src={GreaterThanIcon} alt="" className={styles.headerItemBreadcrumbsIcon} />
          </span>
        ))}
      </span>
      <span className={classNames(styles.breadPath, "desktop-display-none")}>
        {parent ? (
          <span key={parent.fullPath}>
            <img src={LessThanIcon} alt="" className={styles.headerItemBreadcrumbsIcon} />
            <Link.Default url={populateRoute(parent.fullPath, industry)}>
              {parent.title}
            </Link.Default>
          </span>
        ) : null}
      </span>
    </>
  )
}

Breabcrumb.propTypes = {
  uri: PropTypes.string.isRequired,
  industry: IndustryType,
}

Breabcrumb.defaultProps = {
  industry: undefined,
}

export default Breabcrumb
