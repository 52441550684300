/* eslint-disable camelcase */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import FullCircleImage from "../../images/icons/circle-full.svg"
import EmptyCircleImage from "../../images/icons/circle-empty.svg"
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const Ranking = ({ rank, rankText, noRankText }) => {
  const createRankingImages = () => {
    const rankingImages = []

    for (let i = 0; i < 5; i++) {
      if (i < rank) {
        rankingImages.push({
          imageSource: FullCircleImage,
          index: i,
          imageAltText: "Helt sirkelikon",
        })
      } else {
        rankingImages.push({
          imageSource: EmptyCircleImage,
          index: i,
          imageAltText: "Tomt sirkelikon",
        })
      }
    }

    return rankingImages
  }
  const iconStyles = classNames("center-icon", styles.rankingIcon)

  return (
    <>
      {rank === -1 ? (
        <H>{noRankText}</H>
      ) : (
        <H>
          {rankText}
          <span
            className={styles.rankingIconContainer}
            aria-label={`Temaet er rangert som ${rank} av 5`}
          >
            {createRankingImages().map(element => (
              <img
                key={element.index}
                src={element.imageSource}
                alt={element.imageAltText}
                className={iconStyles}
              />
            ))}
          </span>
        </H>
      )}
    </>
  )
}

Ranking.propTypes = {
  rank: PropTypes.number,
  rankText: PropTypes.string.isRequired,
  noRankText: PropTypes.string.isRequired,
}

Ranking.defaultProps = {
  rank: -1,
}

export default Ranking
