import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "../link"
import styles from "./style.module.scss"

const IllustrationLinkMedium = ({
  title,
  imageSource,
  imageAlt,
  linkText,
  url,
  onClick,
  style,
}) => {
  return (
    <div className={classNames(styles.medium, "content")} style={style}>
      <img
        src={imageSource}
        className={classNames(styles.illustration, "desktop-col-6", "content-item")}
        alt={imageAlt}
      />
      <div className={classNames(styles.right, "desktop-col-6", "content-item")}>
        <h3>{title}</h3>
        <Link.Big url={url} onClick={onClick}>
          {linkText}
        </Link.Big>
      </div>
    </div>
  )
}

IllustrationLinkMedium.propTypes = {
  imageSource: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

IllustrationLinkMedium.defaultProps = {
  url: "",
  style: null,
  onClick: null,
}

export default IllustrationLinkMedium
