/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { canTrack } from "../utils"

const GoogleTagManager = () => {
  const env = process.env.GATSBY_INSTANCE_NAME
  const trackableEnv = env === "production"
  const { statistics } = useSelector(s => s.cookieConsent)
  const [canUseAnalytics, setCanUseAnalytics] = useState(false)

  useEffect(() => {
    setCanUseAnalytics(trackableEnv && canTrack(statistics))
  }, [statistics, trackableEnv])

  if (!canUseAnalytics || !window) return null

  window.dataLayer = window.dataLayer || []
  if (!window.dataLayer.find(a => a["gtm.start"]))
    window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })

  return (
    <>
      <Helmet>
        <script
          async
          src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GOOGLE_TAG}`}
        />
      </Helmet>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id${process.env.GATSBY_GOOGLE_TAG}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
    </>
  )
}

export default GoogleTagManager
