/* eslint-disable react/forbid-prop-types */
import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Link as GatsbyLink, navigate } from "gatsby"
import LeftArrow from "../../images/icons/arrow-left-yellow.svg"
import RightArrowYellow from "../../images/icons/arrow-right-yellow.svg"
import RightArrowGray from "../../images/icons/arrow-right-grey.svg"
import RightArrow from "../../images/icons/arrow-right.svg"

import styles from "./style.module.scss"

// Either provide an url to navigate to, or an onClick-function to execute
// TODO: a link should not be a button. Remove buttonType
const BigLink = ({
  url,
  state,
  style,
  arrow,
  onClick,
  grey,
  children,
  displayBlock,
  buttonType,
  newDesign,
  buttonClickLogEvent,
}) => {
  const linkClicked = event => {
    if (buttonClickLogEvent) {
      buttonClickLogEvent()
    }
    if (onClick) {
      if (onClick) {
        event.preventDefault()
        onClick()
      }
    } else {
      navigate(url, { state })
    }
  }

  const leftArrow = (
    <>
      <img src={LeftArrow} className={styles.leftImage} alt={`Gå til ${children}`} />
      <span className={styles.title}>{children}</span>
    </>
  )
  const bigLinkContent = classNames(styles.bigLinkContent, {
    [styles.displayBlock]: displayBlock,
  })

  let arrowIcon = RightArrowYellow
  if (grey) {
    arrowIcon = RightArrowGray
  } else if (newDesign) {
    arrowIcon = RightArrow
  }

  const rightArrow = (
    <div className={bigLinkContent}>
      <span className={styles.title}>{children}</span>
      <img src={arrowIcon} className={styles.rightImage} alt={`Gå til ${children}`} />
    </div>
  )

  const content = arrow === "left" ? leftArrow : rightArrow

  if (buttonType === "button") {
    return (
      <button
        className={classNames(styles.bigLink, {
          [styles.animated]: !newDesign,
          [styles.newDesign]: newDesign,
        })}
        style={{
          ...style,
          display: displayBlock ? "block" : "inline-flex",
          width: displayBlock ? "100%" : "auto",
        }}
        type="button"
        onClick={linkClicked}
      >
        {content}
      </button>
    )
  }

  return (
    <GatsbyLink
      to={url}
      className={classNames(styles.bigLink, {
        [styles.animated]: !newDesign,
        [styles.newDesign]: newDesign,
      })}
      style={{
        ...style,
        display: displayBlock ? "block" : "inline-flex",
      }}
      onClick={linkClicked}
      state={state}
    >
      {content}
    </GatsbyLink>
  )
}

BigLink.propTypes = {
  url: PropTypes.string,
  arrow: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  style: PropTypes.object,
  grey: PropTypes.bool,
  displayBlock: PropTypes.bool,
  buttonType: PropTypes.oneOf(["internal", "button"]),
  state: PropTypes.object,
  newDesign: PropTypes.bool,
  buttonClickLogEvent: PropTypes.func,
}

BigLink.defaultProps = {
  url: null,
  style: null,
  onClick: null,
  arrow: "right",
  grey: false,
  displayBlock: false,
  buttonType: "internal",
  state: null,
  newDesign: false,
  buttonClickLogEvent: null,
}

export default BigLink
