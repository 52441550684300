import React from "react"
import PropTypes from "prop-types"
import GradientSvg from "./gradientSvg"

const CircularProgressbar = ({ labelledby, value }) => {
  return (
    <div className="center" style={{ maxWidth: "140px" }}>
      <GradientSvg percent={value} labelledby={labelledby} />
    </div>
  )
}

CircularProgressbar.propTypes = {
  value: PropTypes.number.isRequired,
  labelledby: PropTypes.string.isRequired,
}

export default CircularProgressbar
