import { navigate } from "gatsby"
import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { GetMenuItems } from "../../constants"
import { Link } from ".."
import { IndustryType } from "../../propTypes"
import InfoModal from "./infoModal"
import styles from "./style.module.scss"
import { H, Section } from "../../state/levelContext"

const MenuView = ({ industry, industries, isOpen, uri, isInfoModalOpen, onInfoModalBtnClick }) => {
  const location = useLocation()

  const menuViewStyles = classNames(styles.menuView, {
    [styles.menuViewOpen]: isOpen,
    [styles.menuViewClosed]: !isOpen,
  })
  const title = "Velg en bransje"
  const text = "Innholdet i portalen er tilpasset ulike bransjer. Velg bransje for å gå videre:"

  const navigateNewIndustry = newIndustryRoute => {
    let newRoute

    const newIndustry = industries.find(i => i.route === newIndustryRoute)

    if (industry) {
      // industry in path. e.g /sykehjem/verktoy/arbeidsmiljohjelpen
      newRoute = uri.replace(`/bransje/${industry.route}`, `/bransje/${newIndustry.route}`)

      const hjelpenRoute = `/verktoy/arbeidsmiljohjelpen`
      if (newRoute.includes(hjelpenRoute) && !newIndustry.tools.includes("hjelpen")) {
        // if user is trying to change industry inside hjelpen to a industry without hjelpen enabled
        newRoute = `/bransje/${newIndustry.route}/verktoy`
      }

      if (newRoute.includes("/styringssloyfen") && newIndustry.route !== "petroleum") {
        // if user is trying to change industry inside hjelpen to a industry without hjelpen enabled
        newRoute = `/bransje/${newIndustry.route}`
      }

      if (!location.pathname.includes(`/bransje/${industry.route}`)) {
        // update tag in query paramter
        newRoute = `${uri}?bransje=${newIndustry.route}`
      }
    } else {
      // industry in query param. e.g /kontakt?bransje=sykehjem
      newRoute = `${uri}?bransje=${newIndustry.route}`
    }
    navigate(newRoute)
  }

  if (!industry || location?.state?.selecIndustry) {
    return (
      <nav aria-label="Hovedmeny">
        <div className={menuViewStyles}>
          <div className={styles.menuViewContainer}>
            <div className={classNames("desktop-col-12")}>
              <h3>{title}</h3>
              <p>{text}</p>
            </div>
          </div>
          <div className={styles.menuViewContainer}>
            {industries
              .filter(i => i.isActive === true)
              .filter(i => !i.showFactsPageOnly)
              .sort((firstIndustry, secondIndustry) => firstIndustry.sort - secondIndustry.sort)
              .map(i => (
                <div key={i.route} className={classNames("desktop-col-4")}>
                  <Link.Big
                    buttonType="button"
                    onClick={() => navigateNewIndustry(i.route)}
                    state={{ isMenuOpen: true }}
                    displayBlock
                  >
                    {i.title}
                  </Link.Big>
                </div>
              ))}
          </div>
          <div className={styles.menuViewContainer} />
        </div>
      </nav>
    )
  }

  const menuItems = GetMenuItems(industry)

  const omCategory = menuItems.find(m => m.title === "Om")

  return (
    <nav aria-label="Hovedmeny">
      <div className={menuViewStyles}>
        <div className={styles.menuViewContainer}>
          <InfoModal
            isOpen={isInfoModalOpen}
            onBtnClick={onInfoModalBtnClick}
            className="tablet-display-none desktop-display-none desktop-col-4 tablet-col-4 mobile-col-12"
          />
          {menuItems.map(
            category =>
              category.mainCategory && (
                <div key={category.title} className="desktop-col-4 tablet-col-4 mobile-col-12">
                  <H className="label h5" style={{ marginBottom: "1rem" }}>
                    {category.title}
                  </H>
                  <Section>
                    {category.subItems.map(item => (
                      <Link.Menu
                        key={`menu-link-${item.title}`}
                        url={item.link}
                        icon={item.external ? "external" : "arrow"}
                        text={item.title}
                        style={{ marginBottom: "1rem" }}
                      />
                    ))}
                  </Section>
                </div>
              )
          )}
        </div>
        <div className={styles.menuViewContainer}>
          <div className="desktop-col-4 tablet-col-4 mobile-col-12">
            <H className="label h5" style={{ marginBottom: "1rem" }}>
              Gå til
            </H>
            <Link.Menu
              url={`/bransje/${industry.route}`}
              icon="home"
              text={`Forside ${industry.title}`}
              style={{ marginBottom: "1rem" }}
            />
            <Link.Menu
              url={`/?bransje=${industry.route}`}
              text="Forside portalen"
              style={{ marginBottom: "1rem" }}
            />
          </div>
          <div className="desktop-col-4 tablet-col-4 mobile-col-12">
            <div className="label h5" style={{ marginBottom: "1rem" }}>
              &nbsp;
            </div>
            <Link.Menu
              url={location.pathname + location.search}
              text="Velg annen bransje"
              style={{ marginBottom: "1rem" }}
              state={{ selecIndustry: true }}
              icon="list"
            />
          </div>
          <div className="desktop-col-4 tablet-col-4 mobile-col-12">
            <H className="label" style={{ marginBottom: "1rem" }}>
              {omCategory.title}
            </H>
            {omCategory.subItems.map(item => (
              <Link.Menu
                key={`menu-link-${item.title}`}
                url={item.link}
                text={item.title}
                style={{ marginBottom: "1rem" }}
                icon="none"
                small
              />
            ))}
          </div>
        </div>
      </div>
    </nav>
  )
}

MenuView.propTypes = {
  industry: IndustryType,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
  isOpen: PropTypes.bool.isRequired,
  uri: PropTypes.string.isRequired,
  isInfoModalOpen: PropTypes.bool,
  onInfoModalBtnClick: PropTypes.func,
}

MenuView.defaultProps = {
  industry: null,
  isInfoModalOpen: false,
  onInfoModalBtnClick: undefined,
}

export default MenuView
