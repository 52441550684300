import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import style from "./style.module.scss"
import { Link } from ".."

const BackLink = ({ to, children, className }) => {
  return (
    <div className={classNames(style.backLink, className)}>
      <div className="desktop-col-12">
        <Link.Big url={to} arrow="left">
          {children !== null ? children : "Til forside"}
        </Link.Big>
      </div>
    </div>
  )
}

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string,
  className: PropTypes.string,
}

BackLink.defaultProps = {
  children: null,
  className: null,
}

export default BackLink
