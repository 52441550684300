/* eslint-disable no-param-reassign */
import React from "react"
import PropTypes from "prop-types"
import DownIcon from "../../images/icons/chevrons-down.svg"
import styles from "./style.module.scss"

const White = ({ text, style }) => {
  const scrollDown = () => {
    const comp = window.document.getElementById("kom-igang")
    comp.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div id="kom-igang" className={styles.whiteContainer} style={style}>
      <button type="button" className={styles.inner} onClick={scrollDown}>
        <p className={styles.text}>{text}</p>
        <div className={styles.circle}>
          <img className={styles.icon} src={DownIcon} alt="" />
        </div>
      </button>
    </div>
  )
}

White.propTypes = {
  text: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

White.defaultProps = {
  text: "Kom i gang!",
  style: null,
}

export default White
