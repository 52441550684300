export const SET_COOKIE_CONSENT = "SET_COOKIE_CONSENT"
export const SET_COOKIE_CONSENT_SAVE = "SET_COOKIE_CONSENT_SAVE"
export const SET_COOKIE_CONSENT_ALLOW_ALL = "SET_COOKIE_CONSENT_ALLOW_ALL"

export const setCookieConsent = cookieConsent => ({
  type: SET_COOKIE_CONSENT,
  cookieConsent,
})

export const setCookieConsentSave = timestamp => ({
  type: SET_COOKIE_CONSENT_SAVE,
  timestamp,
})

export const setCookieConsentAllowAll = timestamp => ({
  type: SET_COOKIE_CONSENT_ALLOW_ALL,
  timestamp,
})
