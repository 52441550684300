import Default from "./default"
import CustomContent from "./customContent"
import Header from "./header"
import VerticalContent from "./verticalContent"

export default {
  Default,
  CustomContent,
  Header,
  VerticalContent,
}
