import React from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import className from "classnames"
import { canTrack } from "../../utils"
import styles from "./style.module.scss"

const VideoPlayer = ({ url, previewImage }) => {
  const { statistics } = useSelector(s => s.cookieConsent)
  let lastProgress = ""

  const googleTagData = (event, action, label = undefined, data = {}) => {
    if (window) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event,
        event_action: action,
        event_label: label,
        ...data,
      })
    }
  }

  const siteImproveTagData = (event, action, label = undefined) => {
    if (window._sz) {
      window._sz.push(["event", event, action, label])
    }
  }

  if (url) {
    return (
      <div className={className(styles.playerWrapper, styles.minHeight)}>
        <ReactPlayer
          className={styles.reactPlayer}
          url={url}
          controls
          width="100%"
          height="100%"
          light={previewImage}
          onStart={() => {
            googleTagData("video", "started", url)
            siteImproveTagData("video", "started", url)
          }}
          onProgress={progress => {
            if (!progress.loaded) return

            let p = ""
            if (progress.played >= 0.95) p = "100"
            else if (progress.played >= 0.75) p = "75"
            else if (progress.played >= 0.5) p = "50"
            else if (progress.played >= 0.25) p = "25"
            else p = "0"

            if (lastProgress !== p) {
              googleTagData("video", `progress_${p}`, url)
              siteImproveTagData("video", `progress_${p}`, url)
              lastProgress = p
            }
          }}
          onEnded={() => {
            googleTagData("video", "completed", url)
            siteImproveTagData("video", "completed", url)
          }}
          playIcon={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <svg
              width="131"
              height="130"
              viewBox="0 0 131 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              alt="Start videoavspilling"
            >
              <circle className={styles.circle} cx="65.7568" cy="65" r="65" fill="#FFDD88" />
              <path
                d="M85.3751 63.3626C86.5971 64.1502 86.5971 65.9371 85.3751 66.7247L57.2858 84.8292C55.9549 85.687 54.2023 84.7316 54.2023 83.1482L54.2023 46.9391C54.2023 45.3557 55.9549 44.4002 57.2858 45.2581L85.3751 63.3626Z"
                fill="white"
              />
            </svg>
          }
          playing
          config={{
            vimeo: {
              playerOptions: {
                dnt: !canTrack(statistics),
              },
            },
          }}
        />
      </div>
    )
  }
  if (previewImage) {
    return (
      <div className={styles.playerWrapper}>
        <img src={previewImage} alt="Video" />
      </div>
    )
  }
  return null
}

VideoPlayer.propTypes = {
  url: PropTypes.string,
  previewImage: PropTypes.string,
}

VideoPlayer.defaultProps = {
  url: "",
  previewImage: undefined,
}

export default VideoPlayer
