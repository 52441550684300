import React from "react"
import PropTypes from "prop-types"

const Level = React.createContext(1)

function Section({ children }) {
  return (
    <Level.Consumer>
      {level => <Level.Provider value={level + 1}>{children}</Level.Provider>}
    </Level.Consumer>
  )
}
function H(props) {
  return (
    <Level.Consumer>
      {level => {
        const Heading = `h${Math.min(level, 6)}`
        return <Heading {...props} />
      }}
    </Level.Consumer>
  )
}

Section.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
}

export { Section, H }
export default Level
