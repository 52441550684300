import React from "react"
import PropTypes from "prop-types"
import Link from "../link"
import styles from "./style.module.scss"

const XSmallIllustrationLink = ({ title, linkText, url, onClick, style }) => {
  return (
    <div className={styles.xsmall} style={style}>
      <h3>{title}</h3>
      <Link.Big url={url} onClick={onClick} grey>
        {linkText}
      </Link.Big>
    </div>
  )
}

XSmallIllustrationLink.propTypes = {
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

XSmallIllustrationLink.defaultProps = {
  url: "",
  style: null,
  onClick: null,
}

export default XSmallIllustrationLink
