import React from "react"
import classNames from "classnames"
import { Link } from ".."
import { GetFrontPageOmLinks } from "../../constants/index"
import LogoAt from "../../images/logo/logo-at.svg"
import LogoHavtil from "../../images/logo/logo-havtil.svg"
import LogoStami from "../../images/logo/logo-stami.svg"
import LogoNav from "../../images/logo/logo-nav.svg"
import styles from "./style.module.scss"
import { Section, H } from "../../state/levelContext"

const FooterFrontpage = () => {
  const frontpageLinks = GetFrontPageOmLinks()
  return (
    <footer className="content">
      <Section>
        <div className={classNames("desktop-col-3 tablet-col-3", styles.partnere)}>
          <H className="h4">Samarbeidspartnere</H>
          <a href="https://www.arbeidstilsynet.no/" target="_blank" rel="noopener external">
            <img src={LogoAt} alt="Arbeidstilsynet" className={styles.logo} />
          </a>
          <a href="https://stami.no/" target="_blank" rel="noopener external">
            <img src={LogoStami} alt="STAMI" className={styles.logo} />
          </a>
        </div>

        <div className={classNames("desktop-col-3 tablet-col-3", styles.partnere)}>
          <H className={classNames(styles.hidden, "mobile-display-none", "h4")}>Partnere</H>
          <a href="https://www.nav.no/" target="_blank" rel="noopener external">
            <img src={LogoNav} alt="NAV" className={styles.logo} />
          </a>
          <a href="https://www.havtil.no//" target="_blank" rel="noopener external">
            <img src={LogoHavtil} alt="Havindustritilsynet" className={styles.logo} />
          </a>
        </div>

        <div className="desktop-col-2 tablet-col-2 mobile-display-none" />

        <div className={classNames(styles.om, "desktop-col-3 tablet-col-3")}>
          <nav aria-label={frontpageLinks.title}>
            <H id="about-title" className="h4">
              {frontpageLinks.title}
            </H>
            <ul>
              {frontpageLinks.items.map(item => (
                <li style={{ marginBottom: "2rem" }} key={`footer-link-${item.title}`}>
                  <Link.Default url={item.link} block>
                    {item.title}
                  </Link.Default>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </Section>
    </footer>
  )
}

export default FooterFrontpage
