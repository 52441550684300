const setParent = (children, parent = null) => {
  children.forEach(r => {
    // eslint-disable-next-line no-param-reassign
    r.parent = parent ? { fullPath: parent.fullPath, title: parent.title } : null
    // eslint-disable-next-line no-param-reassign
    r.fullPath = parent ? parent.fullPath + r.path : r.path
    if (r.children) setParent(r.children, r)
  })

  return children
}

export const SITE_ROUTES = [
  { path: "/", component: "Index", ignoreInBreadcrumb: true, title: "Forside" },
  {
    path: "/bransje/:industryRoute",
    component: "Portal.Main",
    ignoreInBreadcrumb: true,
    title: "Hjem",
    children: [
      {
        path: "/tips-og-rad",
        component: "Portal.TipsOgRad",
        title: "Tips og råd",
      },
      {
        path: "/styringssloyfen",
        component: "Portal.Styringssloyfen",
        title: "Styringssløyfen",
      },
      {
        path: "/roller-og-samarbeid",
        component: "Portal.RollerOgSamarbeid",
        title: "Roller og samarbeid",
      },
      {
        path: "/fakta-om-bransjen",
        component: "Portal.FaktaOmBransjen",
        title: "Fakta om bransjen",
      },
      {
        path: "/bistand-og-ressurser",
        component: "Portal.BistandOgRessurser",
        title: "Bistand og ressurser",
      },
      {
        path: "/vanlige-sporsmal",
        component: "Portal.VanligeSporsmal",
        title: "Vanlige spørsmål",
      },
      {
        path: "/verktoy",
        component: "Portal.Verktoy",
        title: "Verktøy",
        children: [{ path: "*", component: "NotFound", title: "404 - Siden finnes ikke" }],
      },
    ],
  },
  { path: "/personvern", component: "Personvern", title: "Personvern" },
  { path: "/kontakt", component: "Kontakt", title: "Kontakt" },
  { path: "/om", component: "Om", title: "Om portalen" },
  {
    path: "/bedriftshelsetjenesten",
    component: "Bedriftshelsetjenesten",
    title: "Bedriftshelsetjenesten",
  },
  { path: "/kunnskapsmodulen", component: "Kunnskapsmodulen", title: "Kunnskapsmodulen" },
  { path: "*", component: "NotFound", title: "404 - Siden finnes ikke" },
]

export const FLAT_SITE_ROUTES = {}
const flatten = childen => {
  childen.forEach(r => {
    FLAT_SITE_ROUTES[r.fullPath] = r
    if (r.children) flatten(r.children)
  })
}
flatten(setParent(SITE_ROUTES))

export const populateRoute = (route, industry) => {
  let newRoute = route
  newRoute = industry ? newRoute.replace(":industryRoute", industry.route) : newRoute
  return newRoute
}

export const extractRoute = (route, industry) => {
  let newRoute = route
  newRoute = industry ? newRoute.replace(industry.route, ":industryRoute") : newRoute
  return newRoute
}
