/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react"

import PropTypes from "prop-types"
import styles from "./style.module.scss"
import VerticalHeader from "./verticalHeader"
import VerticalTextAccordion from "./verticalTextAccordion"
import { scrollToElement } from "../../utils"

const VerticalContent = ({ items }) => {
  const [activeId, setActiveId] = useState(null)
  const firstItem = items[0]._key

  function handleClick(id) {
    setActiveId(id)
  }

  useEffect(() => {
    setActiveId(firstItem)
  }, [firstItem])

  return (
    <div className={styles.contentContainer}>
      {items.map(({ _key, title, contentTitle, icon, content }) => (
        <>
          <VerticalHeader
            id={_key}
            title={title}
            icon={icon}
            onClick={() => {
              handleClick(_key)
              scrollToElement(null, null, _key)
            }}
            isOpen={activeId === _key}
          />
          <div className={styles.panel}>
            {activeId === _key && (
              <VerticalTextAccordion contentTitle={contentTitle} content={content} icon={icon} />
            )}
          </div>
        </>
      ))}
    </div>
  )
}

VerticalContent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default VerticalContent
