import React from "react"
import { Link as GatsbyLink } from "gatsby"
import classNames from "classnames"
import PropTypes from "prop-types"
import RightArrowYellow from "../../images/icons/arrow-right-yellow.svg"
import styles from "./style.module.scss"
import withDisabledCheck from "../../hooks/withDisabledCheck"
import { H } from "../../state/levelContext"

const ImageLink = ({ children, url, imageSource, imageAltText, imageStyle, disabled }) => {
  const linkClasses = classNames(styles.imageLink, {
    [styles.disabled]: disabled,
  })

  const textWithArrow = (
    <div className={classNames(styles.bigLinkContent)}>
      <H className="h5">{children}</H>
      <img src={RightArrowYellow} className={styles.rightImage} alt={`Gå til ${children}`} />
    </div>
  )
  if (disabled) {
    return (
      <div className={linkClasses}>
        <img
          src={imageSource}
          className={styles.illustration}
          alt={imageAltText}
          style={imageStyle}
        />
        {textWithArrow}
      </div>
    )
  }
  return (
    <GatsbyLink to={url} className={linkClasses}>
      <img
        src={imageSource}
        className={styles.illustration}
        alt={imageAltText}
        style={imageStyle}
      />
      {textWithArrow}
    </GatsbyLink>
  )
}

ImageLink.propTypes = {
  imageSource: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  imageStyle: PropTypes.object,
  disabled: PropTypes.bool,
}

ImageLink.defaultProps = {
  imageStyle: {},
  disabled: false,
}

export default withDisabledCheck(ImageLink)
