import { getIEVersion } from "."

// Scrolls to the HTML element with id="elementId" if condition is true and the element is not visible in window
// IE does not support scrollTo() with 'options'-object https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo

const scrollToElement = (window, condition, elementId) => {
  if (condition) {
    const comp = window.document.getElementById(elementId)
    if (comp.getBoundingClientRect().top < 0) {
      if (getIEVersion() > 0) {
        window.scrollTo(0, comp.offsetTop)
      } else {
        window.scrollTo({ top: comp.offsetTop, behavior: "smooth" })
      }
    }
  }
}

export default scrollToElement
