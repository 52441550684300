const initialState = {
  version: 1,
  lastChanged: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
