/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Button } from ".."
import styles from "./style.module.scss"

const usePopup = (isOpenDefault = false, onClose = null, useNewDesign = false) => {
  const [isOpen, setPopupOpen] = useState(isOpenDefault)
  const [hasBeenOpen, setHasBeenOpen] = useState(false)

  const escFunction = useCallback(event => {
    if (event.key.toLowerCase() === "escape") {
      setPopupOpen(false)
    }
  }, [])

  useEffect(() => {
    if (!isOpen && onClose) {
      if (hasBeenOpen) {
        onClose()
      }
    }
    if (isOpen) {
      setHasBeenOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (!window) return null
    window.document.addEventListener("keydown", escFunction, false)

    return () => {
      if (!window) return
      window.document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  const Modal = ({ children }) => {
    if (!isOpen) {
      return null
    }

    return (
      <div
        className={classNames(styles.overlay, { [styles.overlayImage]: useNewDesign })}
        onClick={() => setPopupOpen(false)}
      >
        <div
          className={classNames(styles.container, { [styles.newContainer]: useNewDesign })}
          onClick={e => e.stopPropagation()}
        >
          <div className={styles.closeBtnContainer}>
            <Button.Tertiary type="button" icon="cross" onClick={() => setPopupOpen(false)}>
              Avbryt og lukk
            </Button.Tertiary>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    )
  }

  Modal.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any.isRequired,
  }

  return [Modal, setPopupOpen]
}

export default usePopup
