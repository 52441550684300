import React from "react"
import { Provider } from "react-redux"
import { createStore as reduxCreateStore } from "redux"
import rootReducer from "."
import { SEO } from "../components"
import { saveState, loadState } from "./localStorage"

const persistedState = loadState()

const createStore = () => {
  if (typeof window !== "undefined") {
    return reduxCreateStore(
      rootReducer,
      persistedState,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  }
  return reduxCreateStore(rootReducer, persistedState)
}

// eslint-disable-next-line react/prop-types
export default ({ element }) => {
  const store = createStore()

  // https://redux.js.org/api/store/#subscribelistener
  store.subscribe(() => {
    saveState(store.getState())
  })

  return (
    <>
      <SEO />
      <Provider store={store}>{element}</Provider>
    </>
  )
}
