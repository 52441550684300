import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useLocation } from "@reach/router"
import classNames from "classnames"
import { Link, Button } from ".."
import { setCookieConsentAllowAll, setCookieConsentSave } from "../../state/cookieConsent/actions"
import CookieIcon from "../../images/icons/cookie-gul.svg"
import styles from "./style.module.scss"
import { useDispatch, eventLogger } from "../../utils"
import useSanity from "../../hooks/useSanity"

const Banner = ({ industryRoute }) => {
  const { lastChanged } = useSelector(s => s.cookieConsent)
  const [show, setShow] = useState(false)
  const location = useLocation()

  // Do not show banner if user has interacted with the cookie consent banner earlier
  useEffect(() => {
    setShow(lastChanged === 0)
  }, [lastChanged])

  useEffect(() => {
    if (show) {
      eventLogger.logShowCookies(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const dispatch = useDispatch()

  const acceptAllClicked = () => {
    eventLogger.logAllowAllCookies(location)
    dispatch(setCookieConsentAllowAll(Date.now()))
  }

  const AcceptMandatoryClicked = () => {
    eventLogger.logAllowMandatoryCookies(location)
    dispatch(setCookieConsentSave(Date.now()))
  }

  const { loading, error, data } = useSanity(`*[_type=="cookieConsentLargeModule"][0]`)

  if (loading || error) return null

  return show ? (
    <>
      <div className={styles.bannerContainer}>
        <div className={styles.imageAndText}>
          <img src={CookieIcon} alt="Cookie" />
          <div className={styles.textBanner}>
            <span>{data.text}</span>
            <Link.Default
              url={industryRoute ? `/personvern?bransje=${industryRoute}` : "/personvern"}
              className={classNames(styles.privacyLink)}
            >
              {data.privacyPolicyLinkText}
            </Link.Default>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button.Secondary
            type="button"
            onClick={AcceptMandatoryClicked}
            className={classNames(styles.button, styles.bakgroundBlack)}
          >
            {data.btnTextRequiredCookies}
          </Button.Secondary>
          <Button.Primary
            type="button"
            onClick={acceptAllClicked}
            className={classNames(styles.button, styles.spaceLeft)}
          >
            {data.btnTextApproveAll}
          </Button.Primary>
        </div>
      </div>
    </>
  ) : null
}

Banner.propTypes = {
  industryRoute: PropTypes.string,
}

Banner.defaultProps = {
  industryRoute: null,
}

export default Banner
