/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */

/*
To be able to override single fields of reusable schema types with industry values, and use default values for the remaining fields,
the front end needs to have an API contract to know which fields are available on complex types.
*/

const types = {
  industry: ["bradag_url", "hjelpen_url"],
  illustrationLinkLarge: ["title", "ingress", "buttonText"],
  hero: ["title", "ingress", "buttonText"],
  accordion: ["title", "content"],
  link: ["text", "href", "external"],
  hjelpen_mainPage_step: [
    "buttonText",
    "footerButtonText",
    "footerPostFixEmpty",
    "footerPostFixPlural",
    "footerPostFixSingle",
    "image",
    "ingress",
    "smallTitle",
    "title",
  ],
  imageWithAlt: ["asset", "alt"],
}

const recursiveDefaultValueCheck = (defaultData, industryData, selectedData) => {
  // "bransjeingress" will typically not have a default value,
  // so we may need to create one here so as not to skip the field in the code that follows
  if (
    Object.keys(industryData).includes("bransjeingress") &&
    !Object.keys(defaultData).includes("bransjeingress")
  ) {
    const { bransjeingress } = industryData
    defaultData.bransjeingress = bransjeingress
  }

  // Same with "preventionPercent"
  if (
    Object.keys(industryData).includes("preventionPercent") &&
    !Object.keys(defaultData).includes("preventionPercent")
  ) {
    const { preventionPercent } = industryData
    defaultData.preventionPercent = preventionPercent
  }

  // Loop through all fields and check if we have an industry value. If yes; use it, if no: use the
  // corresponsing default value
  Object.entries(defaultData).forEach(([key, defaultValue]) => {
    const industryValue = industryData[key]

    if (industryValue === null || industryValue === undefined) {
      selectedData[key] = defaultValue
      return
    }

    if (Array.isArray(industryValue)) {
      selectedData[key] = industryValue.length > 0 ? industryValue : defaultValue
      return
    }

    if (Object.keys(types).includes(industryValue._type)) {
      const fields = types[industryValue._type]
      const result = defaultValue

      fields.forEach(field => {
        result[field] = industryValue[field] || defaultValue[field]
      })

      selectedData[key] = result
      return
    }

    if (typeof industryValue === "object" && defaultValue) {
      selectedData[key] = {}
      recursiveDefaultValueCheck(defaultValue, industryValue, selectedData[key])
      return
    }

    selectedData[key] = industryValue || defaultValue
  })
}

const withDefaults = (nodes, industryRoute) => {
  const industryData =
    nodes.find(node => node.industry.route === `drafts.${industryRoute}`) ||
    nodes.find(node => node.industry.route === industryRoute)
  const defaultData =
    nodes.find(node => node.industry.route === "drafts.default") ||
    nodes.find(node => node.industry.route === "default")
  const selectedData = {}

  // If no industry data, use everything from default
  if (!industryData) {
    return defaultData
  }

  recursiveDefaultValueCheck(defaultData, industryData, selectedData)

  return selectedData
}

export default withDefaults
