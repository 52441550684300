/* eslint-disable react/no-danger, react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button, BlockContent } from ".."
import styles from "./style.module.scss"
import { Section, H } from "../../state/levelContext"

const LargeIllustrationLink = ({
  id,
  smallTitle,
  title,
  content,
  linkText,
  url,
  imageSource,
  imageAltText,
  style,
  imageStyle,
  background,
  border,
  illustrationOverflowY,
  className,
  type,
  external,
  isTool,
  portableContent,
  imageLeftSide,
  buttonClickLogEvent,
}) => {
  const containerStyling = classNames(
    styles.large,
    "content",
    {
      [styles.backgroundYellow]: background === "yellow",
      [styles.backgroundWhite]: background === "white",
      [styles.borderYellow]: border === "yes",
      [styles.illustrationOverflowY]: illustrationOverflowY,
      [styles.illustrationOverflowYHeight]: illustrationOverflowY && isTool,
      "center-content": !isTool,
    },
    className
  )
  const smallScreenButtonStyling = classNames("desktop-display-none", styles.linkButton)
  const largeScreenButtonStyling = classNames(
    "mobile-display-none tablet-display-none",
    styles.linkButton
  )

  const getButton = () => {
    switch (type) {
      case "primary":
        return (
          <Button.Primary
            url={url}
            icon={external ? "external" : "arrow"}
            type={external ? "external" : "internal"}
            buttonClickLogEvent={buttonClickLogEvent}
          >
            {linkText}
          </Button.Primary>
        )
      case "secondary":
        return (
          <Button.Secondary
            url={url}
            icon={external ? "external" : "arrow"}
            type={external ? "external" : "internal"}
            buttonClickLogEvent={buttonClickLogEvent}
          >
            {linkText}
          </Button.Secondary>
        )
      case "tertiary":
        return (
          <Button.Tertiary
            url={url}
            icon={external ? "external" : "arrow"}
            type={external ? "external" : "internal"}
            buttonClickLogEvent={buttonClickLogEvent}
          >
            {linkText}
          </Button.Tertiary>
        )
      default:
        return null
    }
  }

  const getIllustration = () => {
    return (
      <div className="desktop-col-6 center-content">
        <img style={imageStyle} src={imageSource} alt={imageAltText} />
      </div>
    )
  }
  return (
    <div id={id} className={containerStyling} style={style}>
      {imageLeftSide ? getIllustration() : null}
      <div className="desktop-col-6">
        {smallTitle ? <H className="h5">{smallTitle}</H> : null}
        {smallTitle ? (
          <Section>
            <H className="h1">{title}</H>
          </Section>
        ) : (
          <H className="h1">{title}</H>
        )}

        {content && portableContent && (
          <Section>
            <div className={styles.content}>
              <BlockContent blocks={content || []} />
            </div>
          </Section>
        )}
        {content && !portableContent && (
          <div
            className={(styles.content, "markdown")}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}

        {linkText && <div className={largeScreenButtonStyling}>{getButton()}</div>}
      </div>
      {!imageLeftSide ? getIllustration() : null}

      {linkText && <span className={smallScreenButtonStyling}>{getButton()}</span>}
    </div>
  )
}

LargeIllustrationLink.propTypes = {
  id: PropTypes.string,
  smallTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.any,
  linkText: PropTypes.string,
  url: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
  imageAltText: PropTypes.string.isRequired,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
  background: PropTypes.oneOf(["white", "yellow"]),
  border: PropTypes.oneOf(["yes", "no"]),
  illustrationOverflowY: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  external: PropTypes.bool,
  isTool: PropTypes.bool,
  portableContent: PropTypes.bool,
  imageLeftSide: PropTypes.bool,
  buttonClickLogEvent: PropTypes.func,
}

LargeIllustrationLink.defaultProps = {
  id: "",
  background: "white",
  border: "no",
  content: null,
  linkText: null,
  style: {},
  imageStyle: {},
  illustrationOverflowY: false,
  className: null,
  type: "primary",
  external: false,
  smallTitle: null,
  isTool: false,
  portableContent: false,
  imageLeftSide: false,
  buttonClickLogEvent: null,
}

export default LargeIllustrationLink
