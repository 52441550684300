import Primary from "./primary"
import Secondary from "./secondary"
import Tertiary from "./tertiary"
import Smaller from "./smaller"
import DeletePrompt from "./deletePrompt"
import AddNew from "./addNew"

export default {
  Primary,
  Secondary,
  Tertiary,
  Smaller,
  DeletePrompt,
  AddNew,
}
