import { Link as GatsbyLink } from "gatsby"
import React from "react"
import icons from "./_icons"
import types from "./_types"

const createButton = (
  id,
  style,
  classes,
  icon,
  disabled,
  type,
  onClick,
  url,
  text,
  buttonClickLogEvent,
  buttonClickAnalyticsEvent
) => {
  const linkClicked = event => {
    if ((onClick || buttonClickLogEvent || buttonClickAnalyticsEvent) && !disabled) {
      if (buttonClickAnalyticsEvent) {
        buttonClickAnalyticsEvent()
      }
      if (buttonClickLogEvent) {
        buttonClickLogEvent()
      }
      if (onClick) {
        event.preventDefault()
        onClick()
      }
    }
  }

  // If external, show html anchor-tag
  if (type === types.external && !disabled) {
    return (
      <a
        id={id}
        href={url}
        onClick={linkClicked}
        className={classes}
        style={style}
        target="_blank"
        rel="noopener external"
      >
        <span>{text}</span>
        <img src={icon ? icons[icon] : icons.external} alt="" />
      </a>
    )
  }
  // If button, there has to be an onClick-function
  if (type === types.button || disabled) {
    return (
      <button id={id} type="button" onClick={linkClicked} className={classes} style={style}>
        <span>{text}</span>
        {icons[icon] ? <img src={icons[icon]} alt="" /> : null}
      </button>
    )
  }

  // Else, it's internal navigation using a Gatsby link
  return (
    <GatsbyLink id={id} to={url} className={classes} style={style} onClick={buttonClickLogEvent}>
      <span>{text}</span>
      {icons[icon] ? <img src={icons[icon]} alt="" /> : null}
    </GatsbyLink>
  )
}

export default createButton
