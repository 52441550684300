export default `{
  true => {...},
  _type == 'byLogo' => {
    logo {
      asset->,
      alt
    },
  },
  _type == 'infoBox' => {
    icon {
      asset->
    },
  },
  _type == 'attachment' => {
    file {
      asset->
    },
  },
}`
