/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/prop-types */
import React, { useContext } from "react"
import { CodelistCtx } from "../state/codelistProvider"

const withDisabledCheck = WrappedComponent => props => {
  const codelistCtx = useContext(CodelistCtx)
  const { url } = props
  if (!url) {
    return <WrappedComponent {...props} />
  }

  const industries = codelistCtx?.industries?.filter(i => url.includes(i.route))
  if (!industries?.length) {
    return <WrappedComponent {...props} />
  }

  // Skip component if it exsists in the skip pages list
  const industry = industries[0]
  const disable = industry.skipPages.some(skipPage => url.includes(skipPage))
  return <WrappedComponent {...props} disabled={disable} />
}

export default withDisabledCheck
