import React, { useState } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { setCookieConsent, setCookieConsentSave } from "../../state/cookieConsent/actions"
import { Loading, Switch } from ".."
import { useDispatch } from "../../utils"
import styles from "./style.module.scss"
import useSanity from "../../hooks/useSanity"

const Small = () => {
  const [showLabel, setShowLabel] = useState(false)

  const { statistics } = useSelector(s => s.cookieConsent)

  const dispatch = useDispatch()

  const onStatisticsClicked = () => {
    setShowLabel(true)
    dispatch(setCookieConsent({ statistics: !statistics }))
    dispatch(setCookieConsentSave(Date.now()))

    setTimeout(() => {
      setShowLabel(false)
    }, 2500)
  }
  const [params] = useState({ id: "cookieConsentLargeModule" })
  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id]
  {
    editSwitchRequiredCookies,
    editSwitchStatisticsCookies
  } | order(_updatedAt desc) [0]`,
    params
  )

  if (loading || error) return <Loading error={error} />

  return (
    <div className={styles.smallContainer}>
      <div className={styles.row}>
        <span>{data.editSwitchRequiredCookies}</span>
        <div className={styles.innerRow}>
          <Switch on disabled />
          <span className={styles.hidden}>Lagret</span>
        </div>
      </div>
      <div className={styles.row}>
        <span>{data.editSwitchStatisticsCookies}</span>
        <div className={styles.innerRow}>
          <Switch on={statistics} onClick={onStatisticsClicked} />
          <span
            className={classNames(styles.label, {
              [styles.hidden]: !showLabel,
              [styles.fadeOut]: showLabel,
            })}
          >
            Lagret
          </span>
        </div>
      </div>
    </div>
  )
}

export default Small
