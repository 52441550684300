import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import CheckmarkUnchecked from "../../images/icons/checkbox-unchecked.svg"
import CheckmarkChecked from "../../images/icons/checkbox-checked.svg"

const Radio = ({ selectedKey, setSelectedKey, options, style }) => {
  return (
    <div role="radiogroup" className={styles.radiogroup} style={style}>
      {options.map(item => {
        const checked = item.key === selectedKey
        return (
          <button
            type="button"
            role="radio"
            aria-checked={checked ? "true" : "false"}
            key={item.key}
            onClick={() => setSelectedKey(item.key)}
            className={classNames(styles.option, checked ? styles.checked : null)}
          >
            <img
              src={checked ? CheckmarkChecked : CheckmarkUnchecked}
              alt="Ikke valgt radioknapp"
            />
            <span>{item.text}</span>
          </button>
        )
      })}
    </div>
  )
}

Radio.propTypes = {
  selectedKey: PropTypes.string.isRequired,
  setSelectedKey: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

Radio.defaultProps = {
  style: null,
}

export default Radio
