import { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { SanityContext } from "../state/sanityProvider"

export default function useSanity(query, params) {
  const { sanity } = useContext(SanityContext)

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  let mounted = true

  useEffect(() => {
    const updateData = (update = false) => {
      setTimeout(
        () => {
          if (mounted) {
            if (!data) setLoading(true)
            sanity
              .fetch(query, params)
              .then(result => {
                if (mounted) {
                  setData(result)
                  setLoading(false)
                }
              })
              .catch(sanityError => {
                if (mounted) {
                  setError(sanityError)
                  setLoading(false)
                }
              })
          }
        },
        update ? 1000 : 0 // give sanity time to persist update before retrieving it
      )
    }

    let subscription
    if (params !== -1) {
      // use magic value -1 to avoid requesting data two times (initial state + useEffect)
      updateData() // fetch initial dat

      if (process.env.GATSBY_ENV !== "production") {
        subscription = sanity.listen(query, params).subscribe(change => {
          if (change.transition === "update" && change.identity !== "<system>") updateData(true) // Re-fetch data because update does not contain nested resources like assets
        })
      }
    }

    return () => {
      if (subscription) subscription.unsubscribe()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mounted = false
    }
  }, [query, params, sanity])

  return { loading, error, data }
}

useSanity.propTypes = {
  query: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
}
