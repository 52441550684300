import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Input, Button } from ".."
import CheckedIcon from "../../images/icons/checkbox-checked.svg"
import UnCheckedIcon from "../../images/icons/checkbox-unchecked.svg"
import styles from "./style.module.scss"
import { useDispatch } from "../../utils"

const CheckBoxItem = ({
  item,
  onClick,
  style,
  canEdit,
  editBtnText,
  deleteBtnText,
  saveBtnText,
  setDirtyTitle,
  cancelEditTitle,
  updateItem,
  deleteItem,
}) => {
  const [isEditing, setIsEditing] = useState(item.isNew)

  const getIcon = () => {
    return item.isChecked ? (
      <img src={CheckedIcon} alt="Påslått" className={styles.checkIcon} />
    ) : (
      <img src={UnCheckedIcon} alt="Avslått" className={styles.checkIcon} />
    )
  }

  // Actions
  const dispatch = useDispatch()

  const onSetDirtyField = isDirty => {
    dispatch(setDirtyTitle(item.id, isDirty))
  }

  const onCancelEditField = () => {
    dispatch(cancelEditTitle(item.id))
  }

  const onTitleChanged = title => {
    dispatch(updateItem({ itemId: item.id, title }))
  }

  const onDeletePromptYes = () => {
    dispatch(deleteItem(item.id))
  }

  const onEdit = () => {
    setIsEditing(true)
  }

  const onTitleFieldBlur = () => {
    onSetDirtyField(false)
    setIsEditing(false)
  }

  return (
    <>
      {isEditing ? (
        // Edit view
        <div className={styles.container} style={style}>
          <button type="button" className={styles.checkButton} onClick={() => onClick(item.id)}>
            {getIcon()}
          </button>
          <Input.TextField
            name={`Bruker-forbedringspunkt-${item.id}`}
            autoFocus
            placeholder="Nytt punkt..."
            value={item.title}
            onFocus={() => onSetDirtyField(true)}
            onBlur={() => onTitleFieldBlur()}
            onChange={event => onTitleChanged(event.target.value)}
            onCancelEdit={() => onCancelEditField()}
            style={{ marginRight: "1rem", flex: 1 }}
          />
          <div className={styles.buttonMobile}>
            <Button.Tertiary icon="save" type="button" onClick={() => {}}>
              {saveBtnText}
            </Button.Tertiary>
          </div>
        </div>
      ) : (
        // View view
        <div className={classNames(styles.container, styles.backgroundHover)} style={style}>
          <button
            type="button"
            className={styles.checkButton}
            style={{ flex: 1 }}
            onClick={() => onClick(item.id)}
          >
            {getIcon()}
            <span>{item.title}</span>
          </button>
          {canEdit && (
            <div style={{ display: "flex" }}>
              <Button.Tertiary
                icon="pencil"
                type="button"
                onClick={() => onEdit()}
                style={{ marginRight: "1rem" }}
              >
                {editBtnText}
              </Button.Tertiary>
              <Button.DeletePrompt
                onPromptYes={() => onDeletePromptYes()}
                deleteText={deleteBtnText}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

CheckBoxItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    isNew: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  canEdit: PropTypes.bool,
  editBtnText: PropTypes.string,
  deleteBtnText: PropTypes.string,
  saveBtnText: PropTypes.string,
  setDirtyTitle: PropTypes.func,
  cancelEditTitle: PropTypes.func,
  updateItem: PropTypes.func,
  deleteItem: PropTypes.func,
}

CheckBoxItem.defaultProps = {
  style: null,
  canEdit: false,
  editBtnText: "Endre",
  deleteBtnText: "Slett",
  saveBtnText: "Lagre",
  setDirtyTitle: undefined,
  cancelEditTitle: undefined,
  updateItem: undefined,
  deleteItem: undefined,
}

export default CheckBoxItem
