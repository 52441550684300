import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { BlockContent } from ".."

const Fullscreen = ({ title, subtitle, content }) => {
  return (
    <div className="primary-background">
      <div className="content">
        <div className="desktop-col-12">
          <h1 className="h3" style={{ margin: 0 }}>
            {subtitle}
          </h1>
          <h2 className="h1" style={{ margin: 0 }}>
            {title}
          </h2>
          <hr className={styles.fullscreenHorizontalLine} />
          <BlockContent blocks={content || []} />
        </div>
      </div>
    </div>
  )
}

Fullscreen.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default Fullscreen
