import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import ExternalIcon from "../../images/icons/external-link.svg"
import styles from "./style.module.scss"

const BlankLink = ({ url, style, children, external }) => {
  return (
    <a
      href={url}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel="noopener"
      className={classNames(styles.externalLink, styles.animated)}
      style={style}
    >
      <span>{children}</span>
      {external ? <img src={ExternalIcon} alt="External link" /> : null}
    </a>
  )
}

BlankLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  external: PropTypes.bool,
}

BlankLink.defaultProps = {
  style: null,
  external: false,
}

export default BlankLink
