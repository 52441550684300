import classNames from "classnames"
import { ButtonType, ButtonTypeDefaults } from "../../propTypes"
import styles from "./style.module.scss"
import createButton from "./_buttonFactory"
import withDisabledCheck from "../../hooks/withDisabledCheck"

const Tertiary = ({
  id,
  style,
  className,
  icon,
  disabled,
  type,
  onClick,
  url,
  children,
  buttonClickLogEvent,
}) => {
  const classes = classNames(styles.tertiaryButton, className, {
    [styles.disabled]: disabled,
  })

  return createButton(
    id,
    style,
    classes,
    icon,
    disabled,
    type,
    onClick,
    url,
    children,
    buttonClickLogEvent
  )
}

Tertiary.propTypes = ButtonType
Tertiary.defaultProps = ButtonTypeDefaults

export default withDisabledCheck(Tertiary)
