import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"

const Underline = ({ title, smallTitle, subjectIndex, total }) => {
  return (
    <>
      <div className={styles.container}>
        <div>
          {smallTitle && (
            <h1 className="h3" style={{ margin: 0 }}>
              {smallTitle}
            </h1>
          )}
          <h2 className="h1" style={{ margin: 0 }}>
            {title}
          </h2>
        </div>
        {subjectIndex && total ? (
          <h5 style={{ minWidth: "90px" }}>{`Tema ${subjectIndex}/${total}`}</h5>
        ) : null}
      </div>
      <hr className={styles.fullscreenHorizontalLine} />
    </>
  )
}

Underline.propTypes = {
  title: PropTypes.string.isRequired,
  smallTitle: PropTypes.string,
  subjectIndex: PropTypes.number,
  total: PropTypes.number,
}

Underline.defaultProps = {
  smallTitle: null,
  subjectIndex: null,
  total: null,
}

export default Underline
