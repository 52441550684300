import {
  SET_COOKIE_CONSENT,
  SET_COOKIE_CONSENT_SAVE,
  SET_COOKIE_CONSENT_ALLOW_ALL,
} from "./actions"
import { enableGA, disableGA } from "../../utils/googleAnalytics"

const initialState = {
  lastChanged: 0,
  necessary: true,
  statistics: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIE_CONSENT: {
      const { cookieConsent } = action

      return {
        ...state,
        statistics: cookieConsent.statistics,
      }
    }
    case SET_COOKIE_CONSENT_SAVE: {
      const { timestamp } = action
      if (state.statistics) {
        enableGA()
      } else {
        disableGA()
      }

      return {
        ...state,
        lastChanged: timestamp,
      }
    }
    case SET_COOKIE_CONSENT_ALLOW_ALL: {
      const { timestamp } = action

      enableGA()

      return {
        ...state,
        lastChanged: timestamp,
        statistics: true,
      }
    }
    default:
      return state
  }
}
