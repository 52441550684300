/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link as GatsbyLink } from "gatsby"
import ChevronIcon from "../../images/icons/chevron-right.svg"
import ChevronDown from "../../images/icons/chevron-down.svg"
import ChevronUp from "../../images/icons/chevron-up.svg"
import ExternalIcon from "../../images/icons/external-link.svg"
import HomeIcon from "../../images/icons/home.svg"
import ListIcon from "../../images/icons/list.svg"
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const icons = {
  arrow: ChevronIcon,
  down: ChevronDown,
  up: ChevronUp,
  external: ExternalIcon,
  home: HomeIcon,
  list: ListIcon,
  none: null,
}

const MenuLink = ({ url, style, text, icon, small, state, onClick, className }) => {
  const linkStyle = classNames(styles.menuLink, styles.animated, className, {
    [styles.small]: small,
  })
  const getIcon = () => {
    return icons[icon] ? <img src={icons[icon]} alt={`Gå til ${text}`} /> : null
  }

  let result
  if (icon === "external") {
    result = (
      <a href={url} className={linkStyle} target="_blank" rel="noopener noreferrer">
        <H className={classNames(styles.title, styles.h5, "h5")}>{text}</H>
        {getIcon()}
      </a>
    )
  } else if (onClick) {
    result = (
      <button onClick={onClick} className={linkStyle} type="button">
        <H className={classNames(styles.title, styles.h5, "h5")}>{text}</H>
        {getIcon()}
      </button>
    )
  } else {
    result = (
      <GatsbyLink to={url} className={linkStyle} state={state}>
        <H className={classNames(styles.title, styles.h5, "h5")}>{text}</H>
        {getIcon()}
      </GatsbyLink>
    )
  }

  return <div style={style}>{result}</div>
}

MenuLink.propTypes = {
  url: PropTypes.string,
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.keys(icons)),
  small: PropTypes.bool,
  state: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

MenuLink.defaultProps = {
  url: "",
  style: null,
  icon: "arrow",
  small: false,
  state: {},
  onClick: null,
  className: undefined,
}

export default MenuLink
