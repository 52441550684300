import fetch from "node-fetch"
import { customAlphabet } from "nanoid"

const nanoid = customAlphabet("1234567890abcdef", 10)

const logEvent = (url, evnt, domain, path) => {
  const query = {
    event: evnt,
    domain,
    path: encodeURIComponent(path),
    z: nanoid(),
  }
  const queryString = Object.keys(query)
    .map(key => `${key}=${query[key]}`)
    .join("&")

  fetch(`${url}?${queryString}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(response => response.json())
}

export class EventLogger {
  constructor() {
    this.url = `${!process.env.DOMAIN_NAME_ANALYTICS.includes("https") ? "https://" : ""}${
      process.env.DOMAIN_NAME_ANALYTICS
    }/collect`
  }

  logCustomEvent(location, eventName) {
    logEvent(
      this.url,
      eventName,
      location.origin,
      location.pathname + location.search + location.hash
    )
  }

  logSearchEvent(location, eventName, searchPhrase) {
    logEvent(this.url, eventName, location.origin, searchPhrase)
  }

  logAllowAllCookies(location) {
    this.logCustomEvent(location, "at.events.clicks.cookies.all")
  }

  logAllowMandatoryCookies(location) {
    this.logCustomEvent(location, "at.events.clicks.cookies.only_required")
  }

  logShowCookies(location) {
    this.logCustomEvent(location, "at.events.page.cookie_banner_view")
  }

  logPageLoad(location) {
    this.logCustomEvent(location, "at.events.page.page_view")
  }
}

export const logger = new EventLogger()
