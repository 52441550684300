/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import TextAccordion from "./textAccordion"
import styles from "./style.module.scss"

const Accordion = ({
  style,
  itemStyle,
  items,
  backgroundColor,
  border,
  portableContent,
  headerBackgroundColor,
  headerBorderColor,
  alwaysOpen,
}) => {
  return (
    <div className={styles.container} style={style}>
      {items.map(({ _key, title, content }) => (
        <TextAccordion
          id={_key}
          key={_key}
          style={itemStyle}
          title={title}
          content={content}
          backgroundColor={backgroundColor}
          border={border}
          portableContent={portableContent}
          headerBackgroundColor={headerBackgroundColor}
          headerBorderColor={headerBorderColor}
          alwaysOpen={alwaysOpen}
        />
      ))}
    </div>
  )
}

Accordion.propTypes = {
  style: PropTypes.object,
  itemStyle: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  portableContent: PropTypes.bool,
  headerBackgroundColor: PropTypes.oneOf(["yellow", "white"]),
  headerBorderColor: PropTypes.oneOf([null, "yellow", "lightYellow"]),
  alwaysOpen: PropTypes.bool,
}

Accordion.defaultProps = {
  style: null,
  itemStyle: null,
  backgroundColor: "yellow",
  border: "yellow",
  portableContent: false,
  headerBackgroundColor: "yellow",
  headerBorderColor: "yellow",
  alwaysOpen: false,
}

export default Accordion
