import { navigate } from "gatsby"
import { useDispatch as useReduxDispatch } from "react-redux"

const useDispatch = () => {
  const dispatch = useReduxDispatch()
  return action => {
    // If any error arises in any reducer, we navigate the user to an error page
    try {
      dispatch(action)
    } catch (error) {
      navigate("/error", { state: { error } })
    }
  }
}

export default useDispatch
