import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"

const LinkToBody = ({ text }) => {
  return (
    <div id="link-to-body" className={styles.container}>
      <a href="#child-elements">
        <button type="button" className={styles.inner}>
          <span className={styles.text}>{text}</span>
        </button>
      </a>
    </div>
  )
}

LinkToBody.propTypes = {
  text: PropTypes.string,
}

LinkToBody.defaultProps = {
  text: "Til hovedinnhold",
}

export default LinkToBody
