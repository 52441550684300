import React from "react"
import PropTypes from "prop-types"
import Hamburger from "../../images/icons/hamburger.svg"
import Cross from "../../images/icons/cross.svg"
import styles from "./style.module.scss"

const MenuButton = ({ isOpen, setOpen }) => {
  const getOpenMenu = () => {
    return (
      <>
        <span>Lukk</span>
        <div className={styles.imageContainer}>
          <img src={Cross} alt="Lukk meny" />
        </div>
      </>
    )
  }

  const getClosedMenu = () => {
    return (
      <>
        <span>Meny</span>
        <div className={styles.imageContainer}>
          <img src={Hamburger} alt="Åpne meny" />
        </div>
      </>
    )
  }

  return (
    <button className={styles.menuButton} type="button" onClick={() => setOpen(!isOpen)}>
      {isOpen ? getOpenMenu() : getClosedMenu()}
    </button>
  )
}

MenuButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default MenuButton
