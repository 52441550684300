import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import icons from "./_icons"

const AddNew = ({ onClick, title }) => (
  <button type="button" className={styles.addNew} onClick={onClick}>
    <span>{title}</span>
    <img src={icons.plus} alt="Legg til" />
  </button>
)

AddNew.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

AddNew.defaultProps = {
  title: "Legg til nytt punkt",
}

export default AddNew
