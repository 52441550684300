/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import { eventLogger } from "./src/utils"

// eslint-disable-next-line import/prefer-default-export
export { default as wrapRootElement } from "./src/state/reduxWrapper"

export const onRouteUpdate = ({ location }) => {
  const sendPageView = () => {
    if (!window) return null
    eventLogger.logPageLoad(location)

    const pagePath = location ? location.pathname + location.search + location.hash : undefined

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "spa_page_view",
      page_path: pagePath,
    })

    return null
  }
  setTimeout(sendPageView, 200)
}
