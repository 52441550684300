import React, { useRef, useEffect, memo } from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"

const TextField = ({
  name,
  placeholder,
  type,
  value,
  onChange,
  onKeyDown,
  style,
  autoComplete,
  autoFocus,
  maxLength,
  onFocus,
  onBlur,
  onClick,
  onCancelEdit,
  disabled,
  defaultValue,
  unControlled,
}) => {
  const textInput = useRef(null)

  useEffect(() => {
    if (autoFocus && textInput.current) {
      textInput.current.focus()
    }
  }, [autoFocus])

  const onInputKeyDown = event => {
    if (event.key === "Enter") {
      textInput.current.blur()
      onBlur(event)
    }
    if (event.key === "Escape") {
      textInput.current.blur()
      onCancelEdit()
      onBlur(event)
    }
    onKeyDown(event)
  }

  if (unControlled) {
    return (
      <input
        key={defaultValue}
        type={type}
        name={name}
        aria-label={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        ref={textInput}
        style={style}
        className={styles.inputTextField}
        autoComplete={autoComplete.toString()}
        maxLength={maxLength}
        onKeyDown={onInputKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        disabled={disabled}
      />
    )
  }

  return (
    <input
      type={type}
      name={name}
      aria-label={name}
      placeholder={placeholder}
      ref={textInput}
      value={value}
      style={style}
      className={styles.inputTextField}
      autoComplete={autoComplete.toString()}
      maxLength={maxLength}
      onChange={onChange}
      onKeyDown={onInputKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  autoComplete: PropTypes.bool,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onCancelEdit: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  unControlled: PropTypes.bool,
}

TextField.defaultProps = {
  placeholder: "Skriv her...",
  type: "text",
  disabled: false,
  value: "",
  style: null,
  autoComplete: false,
  maxLength: 150,
  autoFocus: false,
  defaultValue: "",
  unControlled: false,
  onChange: () => {},
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onCancelEdit: () => {},
  onClick: () => {},
}

export default memo(TextField)
