import React from "react"
import PropTypes from "prop-types"

const GradientSvg = ({
  strokeBackground,
  strokeColorStart,
  strokeColorEnd,
  percent,
  labelledby,
}) => {
  const viewBoxSize = 36

  const unique = `_${Math.random().toString(36).substr(2, 9)}`

  const strokeWidth = 3
  const radius = 100 / (Math.PI * 2)
  const diameter = radius * 2

  function getMiddleColor(colorA, colorB, mixPoint) {
    const [rA, gA, bA] = colorA.match(/\w\w/g).map(c => parseInt(c, 16))
    const [rB, gB, bB] = colorB.match(/\w\w/g).map(c => parseInt(c, 16))
    const r = Math.round(rA + (rB - rA) * mixPoint)
      .toString(16)
      .padStart(2, "0")
    const g = Math.round(gA + (gB - gA) * mixPoint)
      .toString(16)
      .padStart(2, "0")
    const b = Math.round(bA + (bB - bA) * mixPoint)
      .toString(16)
      .padStart(2, "0")
    return `#${r}${g}${b}`
  }
  const colorGap = 0.025
  const middleColorEnd = getMiddleColor(strokeColorStart, strokeColorEnd, 0.5 + colorGap / 2)
  const middleColorStart = getMiddleColor(strokeColorStart, strokeColorEnd, 0.5 - colorGap / 2)
  return (
    <svg viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
      <defs>
        <linearGradient
          id={`leftGradient${unique}`}
          x={viewBoxSize / 2}
          y={viewBoxSize / 2}
          gradientTransform="rotate(-90 0.5 0.5)"
        >
          <stop offset="0%" stopColor={middleColorStart} />
          <stop offset="100%" stopColor={strokeColorEnd} />
        </linearGradient>
        <linearGradient id={`rightGradient${unique}`} gradientTransform="rotate(90)">
          <stop offset="0%" stopColor={strokeColorStart} />
          <stop offset="100%" stopColor={middleColorEnd} />
        </linearGradient>
        <mask id={`percentRingMask${unique}`}>
          <path
            d={`M${viewBoxSize / 2} ${(viewBoxSize - diameter) / 2}
              a ${radius} ${radius} 0 0 1 0 ${diameter}
              a ${radius} ${radius} 0 0 1 0 -${diameter}`}
            fill="none"
            stroke="white"
            transform={`rotate(0 ${viewBoxSize / 2} ${viewBoxSize / 2})`}
            strokeWidth={strokeWidth}
            strokeDasharray={`${percent}, 100`}
            strokeLinecap="round"
          />
        </mask>
      </defs>
      {/* full grey background circle */}
      <path
        d={`M${viewBoxSize / 2} ${(viewBoxSize - diameter) / 2}
              a ${radius} ${radius} 0 0 1 0 ${diameter}
              a ${radius} ${radius} 0 0 1 0 -${diameter}`}
        fill="none"
        stroke={strokeBackground}
        strokeWidth={strokeWidth}
        strokeDasharray="100, 100"
      />
      {/* "cut" a ring out of the gradient with the ring mask */}
      <g style={{ mask: `url(#percentRingMask${unique})` }}>
        {/* rotate gradient a little bit to hide gradient transition inside the round stroke linecap */}
        <g transform={`rotate(-5.5 ${viewBoxSize / 2} ${viewBoxSize / 2})`}>
          {/* simulate conical gradient by combining two gradients side by side */}
          <rect
            x="0"
            y="0"
            width={(viewBoxSize + 1) / 2}
            height={viewBoxSize}
            fill={`url(#leftGradient${unique})`}
          />
          <rect
            x={viewBoxSize / 2}
            y="0"
            width={viewBoxSize / 2}
            height={viewBoxSize}
            fill={`url(#rightGradient${unique})`}
          />
        </g>
      </g>
      <text x="50%" y="50%" dy="3.5" textAnchor="middle" aria-labelledby={labelledby}>
        <tspan fontSize="10" fontWeight="bold">
          {percent}
        </tspan>
        <tspan fontSize="6" dx="0.4em" dy="-1">
          %
        </tspan>
      </text>
    </svg>
  )
}

GradientSvg.propTypes = {
  strokeColorStart: PropTypes.string,
  strokeColorEnd: PropTypes.string,
  strokeBackground: PropTypes.string,
  percent: PropTypes.number.isRequired,
  labelledby: PropTypes.string.isRequired,
}

GradientSvg.defaultProps = {
  strokeColorStart: "#FFD987",
  strokeColorEnd: "#F06669",
  strokeBackground: "#BFBFBF",
}

export default GradientSvg
