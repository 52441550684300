import { useState } from "react"
import useSanity from "../hooks/useSanity"

export const FORBEDRINGSPUNKT_STATUS = {
  NOT_STARTED: { ID: 1, TEXT: "Ikke startet" },
  IN_PROGRESS: { ID: 2, TEXT: "Igangsatt" },
  DONE: { ID: 3, TEXT: "Utført" },
}

export const FORBEDRINGSPUNKT_PRIORITY = {
  LOW: { ID: 1, TEXT: "Lav" },
  MEDIUM: { ID: 2, TEXT: "Middels" },
  HIGH: { ID: 3, TEXT: "Høy" },
}

export const CONFIG = {
  DEFAULT_SLIDER_VALUE: 3,
  LOCAL_STORAGE_KEY: "SAVED_STATE",
  CURRENT_VERSION: 1,
  THROTTLE_TIME_MS: 2000,
}

export const PETROLEUM_ID = "petroleum"

const getTools = industry => {
  const tools = []

  if (industry.tools.includes("hjelpen")) {
    tools.push({
      title: "Arbeidsmiljøhjelpen",
      external: true,
      link: industry.hjelpen_url,
    })
  }

  if (industry.tools.includes("bradag")) {
    tools.push({
      title: "En bra dag på jobb",
      external: true,
      link: industry.bradag_url,
    })
  }

  if (industry.tools.includes("styringssloyfen")) {
    tools.push({
      title: "Styringssløyfen",
      link: `/bransje/${industry.route}/styringssloyfen`,
    })
  }

  if (industry.tools.length === 0) {
    tools.push({
      title: "Verktøy",
      link: `/bransje/${industry.route}/verktoy`,
    })
  }

  return tools
}

const getKunnskap = industry => {
  const kunnskap = []

  if (!industry.skipPages.includes("fakta-om-bransjen")) {
    kunnskap.push({
      title: "Fakta om bransjen",
      link: `/bransje/${industry.route}/fakta-om-bransjen`,
    })
  }

  if (!industry.skipPages.includes("roller-og-samarbeid")) {
    kunnskap.push({
      title: "Roller og samarbeid",
      link: `/bransje/${industry.route}/roller-og-samarbeid`,
    })
  }

  if (!industry.skipPages.includes("tips-og-rad")) {
    kunnskap.push({
      title: "Tips og råd",
      link: `/bransje/${industry.route}/tips-og-rad`,
    })
  }

  return kunnskap
}

const getHjelp = industry => {
  const hjelp = []

  if (!industry.skipPages.includes("bistand-og-ressurser")) {
    hjelp.push({
      title: "Bistand og ressurser",
      link: `/bransje/${industry.route}/bistand-og-ressurser`,
    })
  }

  if (!industry.skipPages.includes("vanlige-sporsmal")) {
    hjelp.push({
      title: "Vanlige spørsmål",
      link: `/bransje/${industry.route}/vanlige-sporsmal`,
    })
  }

  return hjelp
}

const GetOm = industry => {
  const [params] = useState({ id: "links" })
  const { data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...
      }`,
    params
  )
  const om = []
  const industryName = industry !== null ? `?bransje=${industry.route}` : ""

  if (data !== null) {
    const linkData = data[0]

    if (linkData.om_text !== undefined) {
      om.push({
        title: linkData.om_text,
        link: `${linkData.om_href}${industryName}`,
      })
    }
    if (
      linkData.bedriftshelsetjenesten_text !== undefined &&
      !industry?.skipPages.includes("bedriftshelsetjenesten")
    ) {
      om.push({
        title: linkData.bedriftshelsetjenesten_text,
        link: `${linkData.bedriftshelsetjenesten_href}${industryName}`,
      })
    }
    if (linkData.kunnskapsmodulen_text !== undefined) {
      om.push({
        title: linkData.kunnskapsmodulen_text,
        link: `${linkData.kunnskapsmodulen_href}${industryName}`,
      })
    }
    if (linkData.kontakt_oss_text !== undefined) {
      om.push({
        title: linkData.kontakt_oss_text,
        link: `${linkData.kontakt_oss_href}${industryName}`,
      })
    }
    if (linkData.Personvern_text !== undefined) {
      om.push({
        title: linkData.Personvern_text,
        link: `${linkData.Personvern_href}${industryName}`,
      })
    }
    if (linkData.webtilgengelighed_text !== undefined) {
      om.push({
        title: linkData.webtilgengelighed_text,
        link: `${linkData.webtilgengelighed_href}${industryName}`,
      })
    }
  }
  return om
}

export const GetMenuItems = industry => {
  const tools = getTools(industry)
  const kunnskap = getKunnskap(industry)
  const hjelp = getHjelp(industry)
  const om = GetOm(industry)

  return [
    {
      title: "Kunnskap",
      mainCategory: true,
      subItems: kunnskap,
    },
    {
      title: "Verktøy",
      mainCategory: true,
      subItems: tools,
    },
    {
      title: "Hjelp",
      mainCategory: true,
      subItems: hjelp,
    },
    {
      title: "Om",
      mainCategory: false,
      subItems: om,
    },
  ]
}

export const GetFrontPageOmLinks = () => {
  const om = GetOm(null)
  return {
    title: "Om",
    items: om,
  }
}
