import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { H } from "../../state/levelContext"

const InfoBox = ({ children, title, style, icon, smallIcon, iconAltText }) => {
  const getSmallIconContent = () => (
    <div className={styles.innerContainer}>
      {icon && (
        <div className={styles.smallIcon}>
          <img src={icon} alt={iconAltText || ""} />
        </div>
      )}
      <div>
        {title && <H className={styles.smallHeading}>{title}</H>}
        {children}
      </div>
    </div>
  )

  const getLargeIconContent = () => (
    <div className={styles.innerContainer}>
      {icon && (
        <div className={styles.largeIcon}>
          <img src={icon} alt={iconAltText || ""} />
        </div>
      )}
      <div>
        {title && <H className={styles.largeHeading}>{title}</H>}
        {children}
      </div>
    </div>
  )

  return (
    <div style={style} className={styles.container}>
      {smallIcon ? getSmallIconContent() : getLargeIconContent()}
    </div>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  icon: PropTypes.string,
  smallIcon: PropTypes.bool,
  iconAltText: PropTypes.string,
}

InfoBox.defaultProps = {
  title: null,
  style: null,
  icon: null,
  smallIcon: false,
  iconAltText: null,
  children: null,
}

export default InfoBox
