import React from "react"
import PropTypes from "prop-types"

const ChapterWrapping = ({ title, headerTag: HeaderTag, children }) => {
  return (
    <div className="content" style={{ justifyContent: "space-between" }}>
      <div className="desktop-col-4">
        {title ? <HeaderTag style={{ marginTop: "2rem" }}>{title}</HeaderTag> : null}
      </div>
      <div className="desktop-col-7">{children}</div>
    </div>
  )
}

ChapterWrapping.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  headerTag: PropTypes.string,
}

ChapterWrapping.defaultProps = {
  headerTag: "h3",
  title: null,
}

export default ChapterWrapping
