import Default from "./defaultLink"
import Blank from "./blankLink"
import Big from "./bigLink"
import Menu from "./menuLink"
import Image from "./imageLink"

export default {
  Default,
  Blank,
  Big,
  Menu,
  Image,
}
