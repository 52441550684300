/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import { Title, SEO } from "../components"
import withPageDependencies from "../hoc/withPageDependencies"

const ErrorPage = ({ error }) => {
  const location = useLocation()

  return (
    <>
      <SEO title="Feil på siden" />
      <Title.Default
        title="Uff! Noe gikk galt"
        description="Noe gikk galt under lagring, henting eller sletting av lokal data på nettsiden. Rapporter gjerne feilen til oss ved å ta skjermbilde eller kopiere feilmeldingen under og send på e-post (Se 'Kontakt oss'-siden). For å jobbe videre kan du klikke tilbake i nettleseren og prøve på nytt."
      />
      <div className="content">
        <div className="desktop-col-9">
          <textarea
            className="error-textarea"
            title="error-stacktrace"
            readOnly
            // eslint-disable-next-line no-nested-ternary
            value={location.state ? location?.state?.error?.stack : error ? error?.stack : ""}
          />
        </div>
      </div>
    </>
  )
}

ErrorPage.propTypes = {
  error: PropTypes.object,
}

ErrorPage.defaultProps = {
  error: null,
}

export default withPageDependencies(ErrorPage)
