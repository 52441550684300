const getIEVersion = () => {
  const { userAgent } = window.navigator
  const idX = userAgent.indexOf("MSIE")

  // If IE, return version number
  if (idX > 0) {
    return parseInt(userAgent.substring(idX + 5, userAgent.indexOf(".", idX)))
  }
  // If IE 11 then look for updated user agent string
  if (window.navigator.userAgent.match(/Trident\/7\./)) {
    return 11
  }

  return 0 // It is not IE
}

export default getIEVersion
