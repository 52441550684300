import { combineReducers } from "redux"

import metaData from "./metaData"
import cookieConsent from "./cookieConsent/reducer"
import languageOption from "./selectedLanguageOption/reducer"
import exportIndustry from "./exportIndustry/reducer"

export default combineReducers({
  metaData,
  cookieConsent,
  languageOption,
  exportIndustry,
})
