/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState } from "react"
import classNames from "classnames"
import CrossIcon from "../../images/icons/cross.svg"
import styles from "./style.module.scss"

const DEVELOPMENT = "Development"

const EnvironmentName = () => {
  const [show, setShow] = useState(true)

  if (process.env.GATSBY_INSTANCE_NAME === "production") {
    return <></>
  }

  let text = DEVELOPMENT

  switch (process.env.GATSBY_INSTANCE_NAME) {
    case "test":
      text = "Test"
      break
    case "develop":
    case "dev":
      text = DEVELOPMENT
      break
    default:
      text = DEVELOPMENT
  }

  const envStyle = classNames(styles.container, {
    [styles.marquee]: text === DEVELOPMENT,
    "center-text": text !== DEVELOPMENT,
    "display-none": !show,
  })

  return (
    <div className={envStyle}>
      <span>{text}</span>
      <button className={styles.button} type="button" onClick={() => setShow(false)}>
        <img src={CrossIcon} alt="Lukk" />
      </button>
    </div>
  )
}

export default EnvironmentName
