/* eslint-disable import/prefer-default-export, react/forbid-prop-types */
import PropTypes from "prop-types"
import buttonTypes from "../components/button/_types"
import buttonIcons from "../components/button/_icons"

const { shape, string, bool, arrayOf, object, func } = PropTypes

export const IndustryType = shape({
  _id: string.isRequired,
  tag: string.isRequired,
  isActive: bool.isRequired,
  title: string.isRequired,
  route: string.isRequired,
  tools: arrayOf(string).isRequired,
  bradag_url: string,
  hjelpen_url: string,
})

export const ButtonType = {
  id: string,
  children: string.isRequired,
  type: PropTypes.oneOf(Object.keys(buttonTypes)),
  icon: PropTypes.oneOf(Object.keys(buttonIcons)),
  url: string,
  onClick: func,
  disabled: bool,
  className: string,
  // eslint-disable-next-line react/forbid-prop-types
  style: object,
  buttonClickLogEvent: func,
  newDesign: bool,
}

export const ButtonTypeDefaults = {
  id: null,
  type: buttonTypes.internal,
  icon: null,
  url: "",
  onClick: null,
  disabled: false,
  className: "",
  style: null,
  buttonClickLogEvent: null,
  newDesign: false,
}
