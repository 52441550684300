import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "./index"
import styles from "./style.module.scss"

const DeletePrompt = ({ onPromptYes, promptText, yesText, noText, deleteText }) => {
  const [showPrompt, setShowPrompt] = useState(false)

  return (
    <>
      {showPrompt ? (
        <div className={styles.deletePromptContainer}>
          <span style={{ marginRight: "1rem" }}>{promptText}</span>
          <Button.Tertiary type="button" onClick={onPromptYes}>
            {yesText}
          </Button.Tertiary>
          <Button.Tertiary type="button" onClick={() => setShowPrompt(false)}>
            {noText}
          </Button.Tertiary>
        </div>
      ) : (
        <Button.Tertiary type="button" icon="trash" onClick={() => setShowPrompt(true)}>
          {deleteText}
        </Button.Tertiary>
      )}
    </>
  )
}

DeletePrompt.propTypes = {
  onPromptYes: PropTypes.func.isRequired,
  promptText: PropTypes.string,
  yesText: PropTypes.string,
  noText: PropTypes.string,
  deleteText: PropTypes.string,
}

DeletePrompt.defaultProps = {
  promptText: "Er du sikker?",
  yesText: "Ja",
  noText: "Nei",
  deleteText: "Slett",
}

export default DeletePrompt
