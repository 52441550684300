import RightArrow from "../../images/icons/arrow-right.svg"
import DownArrow from "../../images/icons/arrow-down.svg"
import ExternalIcon from "../../images/icons/external-link.svg"
import PrinterIcon from "../../images/icons/printer.svg"
import EditIcon from "../../images/icons/pencil.svg"
import SaveIcon from "../../images/icons/save.svg"
import CrossIcon from "../../images/icons/cross.svg"
import TrashIcon from "../../images/icons/trash.svg"
import PlusIcon from "../../images/icons/plus.svg"
import FileIcon from "../../images/icons/file.svg"
import MailIcon from "../../images/icons/mail.svg"
import CancelIcon from "../../images/icons/cancel.svg"
import ExcelIcon from "../../images/icons/excel.svg"
import DownloadIcon from "../../images/icons/download.svg"
import PowerPointIcon from "../../images/icons/powerpoint.svg"
import ManusIcon from "../../images/icons/manus.svg"

const icons = {
  null: null,
  arrowDown: DownArrow,
  arrow: RightArrow,
  print: PrinterIcon,
  plus: PlusIcon,
  cross: CrossIcon,
  pencil: EditIcon,
  save: SaveIcon,
  trash: TrashIcon,
  file: FileIcon,
  mail: MailIcon,
  external: ExternalIcon,
  cancel: CancelIcon,
  excel: ExcelIcon,
  download: DownloadIcon,
  powerpoint: PowerPointIcon,
  manus: ManusIcon,
}

export default icons
