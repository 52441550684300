import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./style.module.scss"
import ChevronRightIcon from "../../images/icons/chevron-right.svg"

const VerticalHeader = ({ id, title, onClick, icon, isOpen, alwaysOpen }) => {
  const buttonStyle = () => {
    return classNames(styles.factorBtn, {
      [styles.factorBtnSelected]: isOpen,
    })
  }

  const getIcon = () => {
    if (alwaysOpen) return null

    return (
      <div className={styles.headerRight}>
        <img src={ChevronRightIcon} alt="" />
      </div>
    )
  }
  return (
    <button
      id={id}
      type="button"
      aria-expanded={isOpen}
      className={classNames(buttonStyle(id), styles.btnHeader)}
      onClick={onClick}
    >
      <img src={icon} alt="" className={styles.factorIcon} />
      <p className={styles.factorParagraph}> {title}</p>

      {getIcon()}
    </button>
  )
}

VerticalHeader.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  alwaysOpen: PropTypes.bool,
}
VerticalHeader.defaultProps = {
  title: "",
  icon: "",
  alwaysOpen: false,
}

export default VerticalHeader
