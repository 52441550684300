import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { canTrack } from "../utils"

const SiteImprove = () => {
  const env = process.env.GATSBY_INSTANCE_NAME
  const trackableEnv = env === "production" || env === "test"
  const { statistics } = useSelector(s => s.cookieConsent)
  const [canUseSiteimprove, setCanUseSiteimprove] = useState(false)

  useEffect(() => {
    setCanUseSiteimprove(trackableEnv && canTrack(statistics))
  }, [statistics, trackableEnv])

  if (!canUseSiteimprove) return null

  return (
    <Helmet>
      <script
        type="text/javascript"
        async
        src="https://siteimproveanalytics.com/js/siteanalyze_6003048.js"
      />
    </Helmet>
  )
}

export default SiteImprove
