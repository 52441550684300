/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Header from "./header"
import styles from "./style.module.scss"
import { AccordionContext } from "../../context"
import { scrollToElement } from "../../utils"

const CustomContent = ({
  id,
  style,
  title,
  defaultOpen,
  children,
  backgroundColor,
  headerBackgroundColor,
  headerBorderColor,
  isVisited,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(defaultOpen)
  }, [defaultOpen])

  const contentStyle = classNames(styles.content, styles.yellowBorder, {
    [styles.hidden]: !isOpen,
    [styles.yellowBackground]: backgroundColor === "yellow",
    [styles.whiteBackground]: backgroundColor === "white",
  })

  const toggleAccordion = () => {
    scrollToElement(window, isOpen, id)
    setIsOpen(!isOpen)
  }

  return (
    <AccordionContext.Provider
      value={{
        isOpen,
        toggleAccordion,
      }}
    >
      <div className={styles.item} style={style}>
        <Header
          id={id}
          title={title}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          backgroundColor={headerBackgroundColor}
          borderColor={headerBorderColor}
          isVisited={isVisited}
        />
        <div className={contentStyle}>{children}</div>
      </div>
    </AccordionContext.Provider>
  )
}

CustomContent.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.object.isRequired,
  backgroundColor: PropTypes.oneOf(["yellow", "white"]),
  defaultOpen: PropTypes.bool,
  headerBackgroundColor: PropTypes.oneOf(["yellow", "white"]),
  headerBorderColor: PropTypes.oneOf([null, "yellow", "lightYellow"]),
  isVisited: PropTypes.bool,
}

CustomContent.defaultProps = {
  id: null,
  style: null,
  title: "",
  backgroundColor: "white",
  defaultOpen: false,
  headerBackgroundColor: "yellow",
  headerBorderColor: "yellow",
  isVisited: true,
}

export default CustomContent
