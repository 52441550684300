/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useContext } from "react"
import { compose } from "redux"
import { CodelistCtx, CodelistProvider } from "../state/codelistProvider"

const withInjectedVariables = WrappedComponent => props => {
  const codelistCtx = useContext(CodelistCtx)
  return (
    <WrappedComponent
      industries={codelistCtx.industries}
      industry={codelistCtx.industry}
      {...props}
    />
  )
}

const withCodelistProvider = WrappedComponent => props => {
  return (
    <CodelistProvider industryRoute={props.industryRoute}>
      <WrappedComponent {...props} />
    </CodelistProvider>
  )
}

const withCodelist = compose(withCodelistProvider, withInjectedVariables)

export default withCodelist
