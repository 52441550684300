import React from "react"
import PropTypes from "prop-types"
import { ClientError } from "@sanity/client"
import { Title } from "."
import LoadingImage from "../images/icons/loader.gif"

const Loading = ({ error, hidden }) => {
  const top = (
    <Title.Default
      title="Uff! Noe gikk galt"
      description="Noe gikk galt under lagring, henting eller sletting av data på nettsiden. Rapporter gjerne feilen til oss ved å ta skjermbilde eller kopiere feilmeldingen og send på e-post (Se 'Kontakt oss'-siden). For å jobbe videre kan du klikke tilbake i nettleseren og prøve på nytt."
    />
  )
  if (error)
    if (error instanceof ClientError)
      return (
        <div className="content">
          {top}
          <div className="desktop-col-9">
            <h2>{`${error.details.type}: ${error.details.description}`}</h2>
            {error.details.query ? (
              <pre>
                {error.details.query.substring(0, error.details.start)}
                <span style={{ color: "red" }}>
                  {error.details.query.substring(error.details.start, error.details.end)}
                </span>
                {error.details.query.substring(error.details.end, error.details.query.length)}
              </pre>
            ) : null}
          </div>
        </div>
      )
    else
      return (
        <div className="content">
          {top}
          <div className="desktop-col-9">
            <pre>{JSON.stringify(error, undefined, 2)}</pre>
          </div>
        </div>
      )

  return !hidden ? (
    <div className="content">
      <img src={LoadingImage} alt="Siden laster..." />
    </div>
  ) : null
}

Loading.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(ClientError)]),
  hidden: PropTypes.bool,
}

Loading.defaultProps = {
  error: null,
  hidden: false,
}

export default Loading
