import React from "react"
import PropTypes from "prop-types"
import Link from "../link"
import styles from "./style.module.scss"
import { BlockContent } from ".."
import { H, Section } from "../../state/levelContext"

const IllustrationLinkSmall = ({
  title,
  imageSource,
  imageAlt,
  linkText,
  url,
  style,
  titleStyle,
  bottomContent,
}) => {
  const maxWidth = "239px"
  return (
    <div className={styles.small} style={style}>
      <H className="center-text h3" style={titleStyle}>
        {title}
      </H>
      <Section>
        <Link.Image
          imageStyle={{ maxWidth, alignSelf: "center" }}
          url={url}
          imageSource={imageSource}
          imageAltText={imageAlt}
        >
          {linkText}
        </Link.Image>
      </Section>
      <Section>
        <div style={{ width: maxWidth, alignItems: "flex-start", padding: "8px" }}>
          <BlockContent blocks={bottomContent || []} />
        </div>
      </Section>
    </div>
  )
}

IllustrationLinkSmall.propTypes = {
  imageSource: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyle: PropTypes.object,
  bottomContent: PropTypes.arrayOf(PropTypes.object),
}

IllustrationLinkSmall.defaultProps = {
  url: "",
  style: null,
  titleStyle: null,
  bottomContent: null,
}

export default IllustrationLinkSmall
