import React from "react"
import ReactSlider from "rc-slider"
import PropTypes from "prop-types"
import classNames from "classnames"
import "rc-slider/assets/index.css"
import styles from "./style.module.scss"

const Slider = ({ min, max, defaultValue, step, onChange, style, startLabel, endLabel }) => {
  const getLabels = () => {
    const labels = {}

    for (let index = min; index <= max; index += step) {
      labels[index] = {
        label: index.toString(),
        style: {
          fontSize: "14px",
          marginTop: "1rem",
          fontWeight: "500",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          paddingLeft: index === min ? "2rem" : "1rem",
          paddingRight: index === max ? "2rem" : "1rem",
        },
      }
    }

    return labels
  }

  const internalStyles = {
    display: "flex",
  }

  return (
    <div style={{ ...internalStyles, ...style }}>
      <span className={classNames("mobile-display-none", styles.startLabel)}>{startLabel}</span>
      <ReactSlider
        min={min}
        max={max}
        defaultValue={defaultValue}
        step={step}
        onChange={onChange}
        marks={getLabels()}
        included
        dots={false}
        dotStyle={{ display: "none" }}
        railStyle={{ backgroundColor: "#BFBFBF", height: "8px" }}
        trackStyle={{ backgroundColor: "#FFDD88", height: "8px" }}
        handleStyle={{
          backgroundColor: "#FFDD88",
          border: "none",
          height: "26px",
          width: "26px",
          marginTop: "-10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      />
      <span className={classNames("mobile-display-none", styles.endLabel)}>{endLabel}</span>
    </div>
  )
}

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onChange: PropTypes.func,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
}

Slider.defaultProps = {
  style: null,
  onChange: undefined,
  startLabel: null,
  endLabel: null,
}

export default Slider
