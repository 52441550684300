/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import styles from "./style.module.scss"
import { BlockContent } from ".."
import ChevronsRightIcon from "../../images/icons/chevrons-right.svg"
import { Section } from "../../state/levelContext"

const VerticalTextAccordion = ({ contentTitle, icon, content }) => {
  return (
    <div className={styles.factorContent}>
      <div className={styles.content}>
        <img src={ChevronsRightIcon} alt="" />
        <div className={styles.factorTitle}>
          <h3 className="h4">{contentTitle}</h3>
          <img src={icon} alt="" className={styles.factorInlineHeaderIcon} />
        </div>
        <Section>
          <BlockContent blocks={content || []} />
        </Section>
      </div>
    </div>
  )
}

VerticalTextAccordion.propTypes = {
  contentTitle: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  icon: PropTypes.string,
}

VerticalTextAccordion.defaultProps = {
  content: "",
  icon: null,
}

export default VerticalTextAccordion
