import React, { useState } from "react"
import PropTypes from "prop-types"
import { VideoAndText } from ".."
import InfoIcon from "../../images/icons/info-simple.svg"
import CrossIcon from "../../images/icons/cross.svg"
import TriangleIcon from "../../images/icons/triangle-yellow-white-bottom.svg"
import styles from "./style.module.scss"
import useSanity from "../../hooks/useSanity"
import { blockContentGroq } from "../../groq"

const InfoModal = ({ isOpen, onBtnClick, icon, closeIcon, className }) => {
  const [params] = useState({ id: "videoAndTextModule" })

  const { loading, error, data } = useSanity(
    `*[_id==$id || _id=="drafts."+$id] {
        ...,
        videoPreview {
          ...,
          asset-> {
            url
          }
        },
        sideText[] ${blockContentGroq},
      } | order(_updatedAt desc) [0] `,
    params
  )

  if (loading || error) return null

  const buttonText = isOpen ? data.closeModalBtnText : data.openModalBtnText

  return (
    <div className={className}>
      <button type="button" onClick={() => onBtnClick(!isOpen)} className={styles.infoModalButton}>
        <span>{buttonText}</span>
        <img src={isOpen ? closeIcon : icon} alt="" className={styles.infoModalIcon} />
      </button>
      {isOpen ? (
        <div className={styles.infoModalWindow}>
          <img className={styles.arrow} src={TriangleIcon} alt="" />
          <VideoAndText
            id="hvaerarbeidsmiljo"
            title={data.title}
            videoURL={data.videoURL}
            previewImage={data.videoPreview.asset.url}
            sideText={data.sideText}
            closeBtnText={data.closeBtnText}
            onCloseBtnClick={() => onBtnClick(false)}
          />
        </div>
      ) : null}
    </div>
  )
}

InfoModal.propTypes = {
  icon: PropTypes.string,
  closeIcon: PropTypes.string,
  isOpen: PropTypes.bool,
  onBtnClick: PropTypes.func,
  className: PropTypes.string,
}

InfoModal.defaultProps = {
  icon: InfoIcon,
  closeIcon: CrossIcon,
  isOpen: false,
  onBtnClick: undefined,
  className: undefined,
}

export default InfoModal
