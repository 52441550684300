import { SET_SELECTED_OPTION_LANGUAGE } from "./actions"

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SELECTED_OPTION_LANGUAGE: {
      const { value } = action

      return {
        ...state,
        languageOption: value,
      }
    }
    default:
      return state
  }
}
