import React from "react"
import classNames from "classnames"
import { Link } from ".."
import LogoAt from "../../images/logo/logo-at.svg"
import LogoHavtil from "../../images/logo/logo-havtil.svg"
import LogoStami from "../../images/logo/logo-stami.svg"
import LogoNav from "../../images/logo/logo-nav.svg"
import VersionNumber from "../versionNumber"
import { GetMenuItems } from "../../constants"
import { IndustryType } from "../../propTypes"
import styles from "./style.module.scss"
import { H, Section } from "../../state/levelContext"

const Footer = ({ industry }) => {
  let footerItems = GetMenuItems(industry)

  if (industry.showFactsPageOnly) {
    footerItems = footerItems.filter(i => !["Kunnskap", "Verktøy", "Hjelp"].includes(i.title))
  }

  return (
    <footer className={styles.footerSection}>
      <Section>
        <div className={classNames(styles.footerContent, styles.background)}>
          <div className="desktop-col-12 tablet-col-12 mobile-col-12">
            <H className="h1 title">Godt arbeidsmiljø forebygger sykefravær</H>
            <p className="h5">Det lønner seg å investere i arbeidsmiljøet</p>
          </div>

          <Section>
            {footerItems.map(category => (
              <div
                key={category.title}
                className={
                  category.title === "Verktøy"
                    ? "desktop-col-2 tablet-col-3"
                    : "desktop-col-2 tablet-col-2"
                }
              >
                <H className="h4">{category.title}</H>
                {category.subItems.map(item => (
                  <div
                    key={`footer-link-${item.title}`}
                    style={{ marginBottom: "2rem", lineHeight: "32px" }}
                  >
                    {item.external ? (
                      <Link.Blank url={item.link} external>
                        {item.title}
                      </Link.Blank>
                    ) : (
                      <Link.Default url={item.link}>{item.title}</Link.Default>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </Section>

          <div className="desktop-col-1 tablet-col-1" />
          <Section>
            <div className={classNames("desktop-col-2 tablet-col-2", styles.partnere)}>
              <H className="h4">Samarbeidspartnere</H>
              <a href="https://www.arbeidstilsynet.no/" target="_blank" rel="noopener external">
                <img src={LogoAt} alt="Arbeidstilsynet" className={styles.logo} />
              </a>
              <a href="https://www.havtil.no/" target="_blank" rel="noopener external">
                <img src={LogoHavtil} alt="Havindustritilsynet" className={styles.logo} />
              </a>
              <a href="https://stami.no/" target="_blank" rel="noopener external">
                <img src={LogoStami} alt="STAMI" className={styles.logo} />
              </a>
              <a href="https://www.nav.no/no/bedrift" target="_blank" rel="noopener external">
                <img src={LogoNav} alt="NAV" className={styles.logo} />
              </a>
            </div>
          </Section>
          <VersionNumber />
        </div>
      </Section>
    </footer>
  )
}

Footer.propTypes = {
  industry: IndustryType.isRequired,
}

export default Footer
