import { Link as GatsbyLink } from "gatsby"
import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import MenuButton from "./menuButton"
import InfoModal from "./infoModal"
// import SelectLanguage from "./selectLanguage"
import { Link, LinkToBody, ToTheTopButton } from ".."
import ArbeidsmiljoportalenLogo from "../../images/arbeidsmiljoportalen-logo.svg"
import MenuView from "./menuView"
import styles from "./style.module.scss"
import { IndustryType } from "../../propTypes"
import Breabcrumb from "./breadcrumb"

const Header = ({ industry, industries }) => {
  const location = useLocation()
  const [isMenuOpen, setMenuOpen] = useState(location?.state?.isMenuOpen || false)
  const [isModalOpen, setModalOpen] = useState(false)

  const onMenuButtonClick = open => {
    setModalOpen(false)
    setMenuOpen(open)
  }
  return (
    <>
      <LinkToBody />
      <div className={styles.header}>
        <div className={styles.leftHeader}>
          <GatsbyLink to={industry ? `/?bransje=${industry.route}` : "/"} className={styles.logo}>
            <img src={ArbeidsmiljoportalenLogo} alt="Arbeidsmiljøportalen logo" />
          </GatsbyLink>
          {industry && location.pathname.includes(`/bransje/${industry.route}`) ? (
            <>
              <span style={{ padding: "0 20px" }} className="mobile-display-none">
                <hr width="1" size="32" color="#727279" />
              </span>
              <div className="mobile-display-none">
                <Link.Menu url={`/bransje/${industry.route}`} icon="home" text={industry.title} />
              </div>
              <div className={classNames(styles.headerItemBreadcrumbs, "mobile-display-none")}>
                <Breabcrumb uri={location.pathname} industry={industry} />
              </div>
            </>
          ) : null}
        </div>
        <div className={styles.rightHeader}>
          {/* <SelectLanguage
            isOpen={isModalOpen}
            onBtnClick={setModalOpen}
            className="mobile-display-none"
          /> */}
          <InfoModal
            isOpen={isModalOpen}
            onBtnClick={setModalOpen}
            className="mobile-display-none"
          />
          <MenuButton isOpen={isMenuOpen} setOpen={onMenuButtonClick} />
        </div>
      </div>
      <div
        className={classNames(
          styles.headerItemBreadcrumbs,
          "desktop-display-none",
          "tablet-display-none"
        )}
        style={{ marginBottom: "1em" }}
      >
        <Breabcrumb uri={location.pathname} industry={industry} />
      </div>
      <MenuView
        uri={location.pathname}
        isOpen={isMenuOpen}
        industry={industry}
        industries={industries}
        isInfoModalOpen={isModalOpen}
        onInfoModalBtnClick={setModalOpen}
      />
      <ToTheTopButton />
    </>
  )
}

Header.propTypes = {
  industry: IndustryType,

  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

Header.defaultProps = {
  industry: undefined,
}

export default Header
