import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import CheckmarkOn from "../../images/icons/checkbox-checked-white.svg"
import CheckmarkOff from "../../images/icons/checkbox-unchecked.svg"
import styles from "./style.module.scss"

const Switch = ({ style, on, disabled, onClick, onText, offText }) => {
  const buttonStyle = classNames(styles.switch, {
    [styles.disabled]: disabled,
    [styles.on]: on,
    [styles.off]: !on,
  })

  return (
    <button type="button" style={{ style }} className={buttonStyle} onClick={onClick}>
      <span>{on ? onText : offText}</span>
      <img src={on ? CheckmarkOn : CheckmarkOff} alt={on ? onText : offText} />
    </button>
  )
}

Switch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  on: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onText: PropTypes.string,
  offText: PropTypes.string,
}

Switch.defaultProps = {
  style: null,
  on: false,
  disabled: false,
  onClick: null,
  onText: "På",
  offText: "Av",
}

export default Switch
