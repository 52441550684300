import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import PlusIcon from "../../images/icons/plus.svg"
import MinusIcon from "../../images/icons/minus.svg"
import { scrollToElement } from "../../utils"
import styles from "./style.module.scss"

const Header = ({
  id,
  title,
  isOpen,
  setIsOpen,
  backgroundColor,
  borderColor,
  isVisited,
  alwaysOpen,
}) => {
  const onHeaderClick = () => {
    scrollToElement(window, isOpen, id)
    setIsOpen(!isOpen)
  }

  const headerStyle = classNames(styles.header, {
    [styles.yellowBorderColor]: borderColor === "yellow",
    [styles.lightYellowBorderColor]: borderColor === "lightYellow",
    [styles.yellowBackgroundColor]: backgroundColor === "yellow",
    [styles.whiteBackgroundColor]: backgroundColor === "white",
    [styles.unVisitedHeader]: !isVisited,
    [styles.alwaysOpen]: alwaysOpen,
  })

  const getIcon = () => {
    if (alwaysOpen) return null
    if (isOpen) {
      return (
        <div className={styles.headerRight}>
          <span>Lukk</span>
          <img src={MinusIcon} alt="Lukk" />
        </div>
      )
    }
    return (
      <div className={styles.headerRight}>
        <span>Åpne</span>
        <img src={PlusIcon} alt="Åpne" />
      </div>
    )
  }
  return (
    <button id={id} type="button" className={headerStyle} onClick={onHeaderClick}>
      <span>{title}</span>
      {getIcon()}
    </button>
  )
}

Header.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  backgroundColor: PropTypes.oneOf([null, "yellow", "white"]),
  borderColor: PropTypes.oneOf([null, "lightYellow", "yellow"]),
  isVisited: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
}
Header.defaultProps = {
  title: "",
  backgroundColor: "yellow",
  borderColor: "yellow",
  isVisited: true,
  alwaysOpen: false,
}
export default Header
