/* eslint-disable react/prop-types */
import React from "react"
import CalendarLib from "react-calendar"
import "react-calendar/dist/Calendar.css"
import PropTypes from "prop-types"
import styles from "./style.module.scss"

const Calendar = ({ onChange, value, name, onClickDay, minDate }) => {
  const handleOnChange = date => {
    onChange({
      currentTarget: {
        name,
        value: date,
      },
    })
  }

  const handleOnClickDay = data => {
    if (onClickDay) {
      onClickDay(data)
    }
  }

  const getDayStyle = ({ date, view }) => {
    if (
      view === "month" &&
      value.getDate() === date.getDate() &&
      value.getMonth() === date.getMonth()
    ) {
      return styles.activeDay
    }
    return null
  }

  return (
    <CalendarLib
      tileClassName={getDayStyle}
      onChange={handleOnChange}
      value={value}
      locale="no-nb"
      onClickDay={handleOnClickDay}
      view="month"
      minDate={minDate}
    />
  )
}

Calendar.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.object,
  name: PropTypes.string.isRequired,
  onClickDay: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  minDate: PropTypes.object,
}

Calendar.defaultProps = {
  value: null,
  onClickDay: null,
  minDate: null,
}

export default Calendar
