import PropTypes from "prop-types"

const canTrack = statistics => {
  if (typeof window === "undefined") return false

  if (window.navigator && window.navigator.doNotTrack === "1") {
    return false
  }
  if (window.navigator && window.navigator.msDoNotTrack === "1") {
    return false
  }
  if (window.doNotTrack === "1") {
    return false
  }
  if (!statistics) {
    return false
  }

  return true
}

canTrack.propTypes = {
  statistics: PropTypes.bool.isRequired,
}

export default canTrack
