import { SET_INDUSTRY_EXPORTED } from "./actions"
import { createUuid } from "../../utils"

export default (state = {}, action) => {
  switch (action.type) {
    case SET_INDUSTRY_EXPORTED: {
      const { industryId } = action

      // If the user has already exported this industry, let it be
      if (Object.values(state).find(s => s.industryId === industryId && s.exported)) {
        return state
      }

      const id = createUuid()

      return {
        ...state,
        [id]: {
          industryId,
          exported: true,
        },
      }
    }
    default:
      return state
  }
}
