import React from "react"
import PropTypes from "prop-types"
import { ChapterWrapping, BlockContent } from "."

const Chapter = ({ title, content, headerTag: HeaderTag }) => {
  return (
    <ChapterWrapping title={title} headerTag={HeaderTag}>
      <BlockContent blocks={content || []} />
    </ChapterWrapping>
  )
}

Chapter.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  headerTag: PropTypes.string,
}

Chapter.defaultProps = {
  headerTag: "h3",
}

export default Chapter
