const gaCookie = "ga-disable-UA-156102109-1"

const disableGA = () => {
  window.document.cookie = `${gaCookie}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`
  window[gaCookie] = true
}

const enableGA = () => {
  window.document.cookie = `${gaCookie}=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`
  window[gaCookie] = false
}

export { enableGA, disableGA }
