import React, { useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { getColorStyles } from "./colorStyles"

function DropDown({ name, options, optionValue, onChange, className, placeholder, ariaLabel }) {
  const [isMenuOpen, setMenuState] = useState(false)
  return (
    <div className={className}>
      <select
        aria-label={ariaLabel}
        onChange={event => onChange(event.currentTarget)}
        className="desktop-display-none tablet-display-none"
        onFocus={() => setMenuState(true)}
        onBlur={() => setMenuState(false)}
        value={optionValue ? optionValue.value : ""}
      >
        {placeholder ? (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        ) : null}
        {options.map(({ value, label }) => {
          return (
            <option key={label} value={value}>
              {label}
            </option>
          )
        })}
      </select>
      <Select
        name={name}
        className="mobile-display-none"
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#FFDD88",
            primary: "#EDB932",
          },
        })}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        styles={getColorStyles(isMenuOpen)}
        value={optionValue}
        onMenuOpen={() => setMenuState(true)}
        onMenuClose={() => setMenuState(false)}
        blurInputOnSelect="true"
        aria-label={ariaLabel}
      />
    </div>
  )
}

DropDown.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  optionValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
}

DropDown.defaultProps = {
  placeholder: "",
  className: "",
  optionValue: null,
  ariaLabel: null,
}

export default DropDown
