import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link } from ".."
import styles from "./style.module.scss"
import { IndustryType } from "../../propTypes"
import { H, Section } from "../../state/levelContext"

function FindIndustryFullWidth({ title, text, postFix, industries }) {
  const getFullPath = industry => {
    if (postFix) {
      return `/bransje/${industry.route}${postFix}`
    }

    return `/bransje/${industry.route}`
  }

  return (
    <nav
      aria-label={title}
      className={classNames("content", "primary-background", "all-industries")}
    >
      <div className={classNames("content", styles.findIndustryFullWidth)}>
        <div className={classNames("desktop-col-9", "center-text center")}>
          <H>{title}</H>
          <p className="h5">{text}</p>
        </div>
        <div className="break" />
        <Section>
          <ul>
            {industries
              .filter(i => i.isActive === true)
              .filter(i => !i.showFactsPageOnly)
              .sort((firstIndustry, secondIndustry) => firstIndustry.sort - secondIndustry.sort)
              .map(industry => (
                <li key={industry.route} className={classNames("desktop-col-6")}>
                  <Link.Big url={getFullPath(industry)} displayBlock>
                    {industry.title}
                  </Link.Big>
                </li>
              ))}
          </ul>
        </Section>
      </div>
    </nav>
  )
}

FindIndustryFullWidth.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  postFix: PropTypes.string,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

FindIndustryFullWidth.defaultProps = {
  postFix: null,
}

export default FindIndustryFullWidth
