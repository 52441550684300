import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button, VideoPlayer } from ".."
import BlockContent from "../blockContent"
import styles from "./style.module.scss"
import { H, Section } from "../../state/levelContext"

const VideoAndText = ({
  title,
  videoURL,
  previewImage,
  sideText,
  closeBtnText,
  onCloseBtnClick,
  id,
  className,
  style,
}) => {
  return (
    <section
      id={id}
      className={classNames("content", className, styles.wrapper)}
      style={style}
      aria-label={title}
    >
      {title ? <H className="desktop-col-12 center-text h1">{title}</H> : null}

      <div className="desktop-col-6">
        <VideoPlayer url={videoURL} previewImage={previewImage} />
      </div>

      <Section>
        <div className="desktop-col-5">
          <BlockContent blocks={sideText} />
          {closeBtnText ? (
            <Button.Secondary
              type="button"
              onClick={onCloseBtnClick}
              icon="cross"
              style={{ marginTop: "4rem" }}
            >
              {closeBtnText}
            </Button.Secondary>
          ) : null}
        </div>
      </Section>
    </section>
  )
}

VideoAndText.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  videoURL: PropTypes.string,
  previewImage: PropTypes.string.isRequired,
  sideText: PropTypes.arrayOf(PropTypes.object),
  closeBtnText: PropTypes.string,
  onCloseBtnClick: PropTypes.func,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

VideoAndText.defaultProps = {
  title: null,
  videoURL: "",
  sideText: [],
  closeBtnText: undefined,
  onCloseBtnClick: undefined,
  className: "",
  style: {},
}

export default VideoAndText
