import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Link as GatsbyLink, navigate } from "gatsby"
import styles from "./style.module.scss"
import withDisabledCheck from "../../hooks/withDisabledCheck"

const DefaultLink = ({ url, style, className, children, onClick, disabled }) => {
  const linkClicked = event => {
    if (disabled) return

    if (onClick) {
      event.preventDefault()
      onClick()
    } else {
      navigate(url)
    }
  }

  return (
    <GatsbyLink
      className={classNames(
        styles.defaultLink,
        {
          [styles.animated]: !disabled,
          [styles.disabled]: disabled,
        },
        className
      )}
      to={disabled ? null : url}
      style={style}
      onClick={linkClicked}
    >
      {children}
    </GatsbyLink>
  )
}

DefaultLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

DefaultLink.defaultProps = {
  url: "",
  style: null,
  onClick: null,
  disabled: false,
  className: "",
}

export default withDisabledCheck(DefaultLink)
