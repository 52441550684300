import React from "react"
import PropTypes from "prop-types"
import "../../styles/styles.scss"
import { Footer, Header, SiteImprove, EnvironmentName, CookieConsent, GoogleTagManager } from ".."
import { IndustryType } from "../../propTypes"

const Default = ({ children, industry, industries }) => {
  return (
    <>
      <GoogleTagManager />
      <EnvironmentName />
      <header>
        <Header industry={industry} industries={industries} />
      </header>
      <div>
        <CookieConsent.Banner industryRoute={industry ? industry.route : undefined} />
      </div>
      <main>
        <div id="child-elements">{children}</div>
      </main>
      {industry ? <Footer.Default industry={industry} /> : <Footer.Frontpage />}
      <SiteImprove />
    </>
  )
}

Default.propTypes = {
  children: PropTypes.node.isRequired,
  industry: IndustryType,
  industries: PropTypes.arrayOf(IndustryType).isRequired,
}

Default.defaultProps = {
  industry: null,
}

export default { Default }
