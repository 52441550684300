import React, { createContext, useContext } from "react"
import { compose } from "redux"
import PropTypes from "prop-types"
import sanityClient from "@sanity/client"

export const SanityContext = createContext({})

const envName = process.env.GATSBY_ENV

const sanity = sanityClient({
  apiVersion: "v1",
  projectId: process.env.SANITY_STUDIO_API_PROJECT_ID,
  dataset: process.env.SANITY_STUDIO_API_DATASET,
  useCdn: false,
  withCredentials: envName !== "production",
})

const withInjectedSanityVariable = WrappedComponent => props => {
  const sanityContext = useContext(SanityContext)
  return <WrappedComponent sanity={sanityContext.sanity} {...props} />
}

const withSanityProvider = WrappedComponent => props => {
  return (
    <SanityContext.Provider value={{ sanity }}>
      <WrappedComponent {...props} />
    </SanityContext.Provider>
  )
}

withSanityProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
}

export const withSanity = compose(withSanityProvider, withInjectedSanityVariable) // remove withInjectedSanityVariable?
