import React from "react"
import PropTypes from "prop-types"
import DownIcon from "../../images/icons/chevrons-down.svg"
import styles from "./style.module.scss"

const Yellow = ({ text, style }) => {
  const scrollDown = () => {
    const comp = window.document.getElementById("scroll-ned")
    comp.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div id="scroll-ned" className={styles.yellowContainer} style={style}>
      <button type="button" className={styles.inner} onClick={scrollDown}>
        <span className={styles.text}>{text}</span>
        <div className={styles.circle}>
          <img className={styles.icon} src={DownIcon} alt="" />
        </div>
      </button>
    </div>
  )
}

Yellow.propTypes = {
  text: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

Yellow.defaultProps = {
  text: "Scroll ned for mer",
  style: null,
}

export default Yellow
