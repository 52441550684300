/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Header from "./header"
import styles from "./style.module.scss"
import { BlockContent } from ".."

const TextAccordion = ({
  id,
  style,
  title,
  defaultOpen,
  content,
  backgroundColor,
  border,
  portableContent,
  headerBackgroundColor,
  headerBorderColor,
  alwaysOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(defaultOpen || alwaysOpen)
  }, [defaultOpen, alwaysOpen])

  const onHeaderClick = open => {
    if (alwaysOpen) return
    setIsOpen(open)
  }
  const contentStyle = classNames(styles.content, {
    [styles.hidden]: !isOpen,
    [styles.linkify]: !portableContent,
    [styles.yellowBackground]: backgroundColor === "yellow",
    [styles.whiteBackground]: backgroundColor === "white",
    [styles.yellowBorder]: border === "yellow",
  })

  if (portableContent)
    return (
      <div className={styles.item} style={style}>
        <Header
          id={id}
          backgroundColor={headerBackgroundColor}
          borderColor={headerBorderColor}
          title={title}
          isOpen={isOpen}
          setIsOpen={onHeaderClick}
          alwaysOpen={alwaysOpen}
        />
        <div className={contentStyle}>
          <BlockContent blocks={content || []} />
        </div>
      </div>
    )

  return (
    <div className={styles.item} style={style}>
      <Header
        id={id}
        backgroundColor={headerBackgroundColor}
        borderColor={headerBorderColor}
        title={title}
        isOpen={isOpen}
        setIsOpen={onHeaderClick}
      />
      <div className={contentStyle} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  )
}

TextAccordion.propTypes = {
  id: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  backgroundColor: PropTypes.oneOf(["yellow", "white"]),
  border: PropTypes.oneOf(["", "yellow"]),
  defaultOpen: PropTypes.bool,
  portableContent: PropTypes.bool,
  headerBackgroundColor: PropTypes.oneOf(["yellow", "white"]),
  headerBorderColor: PropTypes.oneOf([null, "lightYellow", "yellow"]),
  alwaysOpen: PropTypes.bool,
}

TextAccordion.defaultProps = {
  id: null,
  style: null,
  content: "",
  backgroundColor: "yellow",
  border: "",
  defaultOpen: false,
  portableContent: null,
  headerBackgroundColor: "yellow",
  headerBorderColor: "yellow",
  alwaysOpen: false,
}

export default TextAccordion
