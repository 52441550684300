import React from "react"
import PropTypes from "prop-types"
import { BlockContent } from ".."
import { H } from "../../state/levelContext"

function Title({ title, description, content }) {
  return (
    <div className="content" style={{ paddingBottom: "3rem", paddingTop: "3rem" }}>
      <div className="desktop-col-9 center-text">
        <H className="h1">{title}</H>
        {description !== null ? <p className="h5">{description}</p> : ""}
        {content !== null ? <BlockContent blocks={content || []} /> : null}
      </div>
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.any,
}

Title.defaultProps = {
  description: null,
  content: null,
}

export default Title
